<template>
  <video
    ref="myVideo"
    controls
    autoplay
    loop
    muted
    style="width: auto; height: auto"
    @loadedmetadata="onVideoLoaded"
  ></video>
</template>

<script>
import Hls from "hls.js";

export default {
  name: "hlsPlayer",
  props: {
    videoSrc: String,
  },
  data() {
    return {
      hls: null, // HLS instance
      startTime: 0,
    };
  },
  watch: {
    // Observer la prop `videoSrc` pour relancer le lecteur si la source change
    videoSrc(newSrc) {
      this.initPlayer(newSrc);
    },
  },
  methods: {
    initPlayer(src) {
      const video = this.$refs.myVideo;
      if (!video || !src) return;

      video.setAttribute("autoplay", "");
      video.setAttribute("loop", "");

      if (Hls.isSupported()) {
        if (this.hls) {
          this.hls.destroy();
        }
        this.hls = new Hls({
          maxBufferLength: 15,
          maxMaxBufferLength: 30,
          liveSyncDurationCount: 3,
          liveMaxLatencyDurationCount: 5,
          enableWorker: true,
          lowLatencyMode: false,
        });

        this.hls.loadSource(src);
        this.hls.attachMedia(video);

        this.hls.on(Hls.Events.BUFFER_STALLED_ERROR, () => {
          console.warn("Buffer error, next segment...");
          this.hls.nextLoadPosition += 10;
        });
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = src;
      }
    },
    onVideoLoaded() {
      const savedPosition = localStorage.getItem("videoPosition");
      if (savedPosition) {
        this.$refs.myVideo.currentTime = parseFloat(savedPosition);
      } else {
        this.$refs.myVideo.currentTime = this.startTime;
      }
    },
  },
  mounted() {
    this.initPlayer(this.videoSrc); // Initialisation du lecteur quand le composant est monté
  },
  beforeUnmount() {
    // Sauvegarder la position actuelle de la vidéo dans le localStorage
    const video = this.$refs.myVideo;
    if (video) {
      const currentTime = video.currentTime; // Utilise video.currentTime pour obtenir la position actuelle
      localStorage.setItem("videoPosition", currentTime.toString());
    }
  },
};
</script>
