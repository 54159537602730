<template>
  <div>
    <!-- modal search cross -->
    <div
      v-if="openModalSearch || openModalSearchVanHeck"
      :class="`modal ${
        !openModalSearch &&
        !openModalSearchVanHeck &&
        'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="(openModalSearch = false), (openModalSearchVanHeck = false)"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div class="flex space-x-6">
        <div
          style="max-width: 80rem; max-height: 50rem"
          class="z-50 w-11/12 mx-auto bg-gray-200 rounded shadow-lg modal-container"
        >
          <div
            class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
          >
            <svg
              class="text-white fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
            <span class="text-sm">(Esc)</span>
          </div>

          <!-- Add margin if you want to see some of the overlay behind the modal-->
          <div class="px-6 py-4 text-left modal-content">
            <!--Title-->
            <div class="flex items-center justify-between pb-3">
              <p class="text-2xl font-bold">Références</p>
              <div
                class="z-50 cursor-pointer modal-close"
                @click="
                  (openModalSearch = false), (openModalSearchVanHeck = false)
                "
              >
                <svg
                  class="text-black fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <path
                    d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                  />
                </svg>
              </div>
            </div>

            <!-- Body -->
            <div class="flex flex-col mt-4" style="max-height: 42rem">
              <!-- <button
                @click="showCatRef = true"
                class="p-2 bg-blue-500 text-xs text-white rounded shadow"
              >
                Références Catalogue
              </button> -->
              <div
                class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
              >
                <div
                  class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
                >
                  <table class="min-w-full">
                    <thead>
                      <tr>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                        >
                          Réf.
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                        >
                          Descr.
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                        >
                          Prix
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                        >
                          Stock
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                        ></th>

                        <th
                          class="px-6 py-3 border-b border-gray-200 bg-gray-50"
                        ></th>
                      </tr>
                    </thead>

                    <tbody class="bg-white">
                      <tr
                        v-for="(u, index) in crossoetab"
                        :key="index"
                        class="hover:bg-gray-100"
                      >
                        <td
                          class="px-2 border-b border-gray-200 whitespace-nowrap tableTDTH"
                        >
                          <div class="flex flex-col">
                            <div class="flex flex-row items-center">
                              <div
                                class="space-x-1 flex text-sm leading-5 text-gray-900 font-medium"
                              >
                                <span
                                  class="material-icons-outlined m-auto cursor-pointer text-orange-500 hover:bg-gray-100 rounded hover:shadow hover:animate-spin"
                                  style="font-size: 11px"
                                  @click="
                                    searchCross(u.articleNo),
                                      (open = !open),
                                      (searchInput = u.articleNo)
                                  "
                                >
                                  cached
                                </span>
                                <span>{{ u.articleNo }}</span>
                                <span
                                  class="material-icons-outlined m-auto cursor-pointer text-blue-500 hover:bg-gray-100 rounded hover:shadow"
                                  style="font-size: 12px"
                                  @click="plusInfo(u.articleNo, u.brandName)"
                                >
                                  info
                                </span>
                              </div>
                            </div>
                            <div class="text-sm leading-5 text-gray-500">
                              {{ u.brandName }}
                            </div>
                          </div>
                        </td>

                        <td
                          class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                        >
                          <div class="text-sm leading-5 text-gray-900">
                            {{ u.articleName }}
                          </div>
                        </td>

                        <td
                          class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                        >
                          <span
                            v-if="prixtab[index]"
                            class="text-sm leading-5 text-blue-500"
                            >{{
                              parseFloat(prixtab[index].prix).toFixed(2)
                            }}
                            €</span
                          >
                        </td>

                        <td
                          class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                        >
                          <div class="flex space-x-1" v-if="prixtab[index]">
                            <!-- <img style="width:1rem" src="../assets/IconsDelivery/contrast.png"/>
                          <img style="width:1rem" src="../assets/IconsDelivery/day-and-night.png"/>
                          <img style="width:1rem" src="../assets/IconsDelivery/half-moon.png"/>
                          <img style="width:1rem" src="../assets/IconsDelivery/sun.png"/> -->
                            <div
                              v-if="prixtab[index] && prixtab[index].stock > 0"
                            >
                              <span
                                @mouseover="showIconDispoA[index] = true"
                                @mouseleave="showIconDispoA[index] = false"
                                style="width: 1.75rem; height: 1.75rem"
                                class="material-icons-outlined p-0.5 rounded-full bg-green-500 text-white"
                                >check_circle</span
                              >
                              <div
                                class="absolute bg-gray-200 p-2 rounded shadow-lg z-20"
                                v-show="showIconDispoA[index]"
                              >
                                <span>Disponible</span>
                              </div>
                            </div>

                            <div>
                              <span
                                @mouseover="showIconFiliale1A[index] = true"
                                @mouseleave="showIconFiliale1A[index] = false"
                                style="width: 1.75rem; height: 1.75rem"
                                class="material-icons-outlined p-0.5 rounded-full bg-cyan-500 text-white"
                                >swap_horizontal_circle</span
                              >
                              <div
                                class="absolute bg-gray-200 p-2 rounded shadow-lg z-20"
                                v-show="showIconFiliale1A[index]"
                              >
                                <span>Disponible filiale 1</span>
                              </div>
                            </div>

                            <div>
                              <span
                                @mouseover="showIconFiliale2A[index] = true"
                                @mouseleave="showIconFiliale2A[index] = false"
                                style="width: 1.75rem; height: 1.75rem"
                                class="material-icons-outlined p-0.5 rounded-full bg-blue-500 text-white"
                                >swap_horizontal_circle</span
                              >
                              <div
                                class="absolute bg-gray-200 p-2 rounded shadow-lg z-20"
                                v-show="showIconFiliale2A[index]"
                              >
                                <span>Disponible filiale 2</span>
                              </div>
                            </div>

                            <div
                              v-if="
                                (prixtab[index] && prixtab[index].stock < 1) ||
                                !prixtab[index].stock
                              "
                            >
                              <span
                                @mouseover="showIconCmdA[index] = true"
                                @mouseleave="showIconCmdA[index] = false"
                                style="width: 1.75rem; height: 1.75rem"
                                class="material-icons-outlined p-0.5 rounded-full bg-orange-500 text-white"
                                >help_outline</span
                              >
                              <div
                                class="absolute bg-gray-200 p-2 rounded shadow-lg z-20"
                                v-show="showIconCmdA[index]"
                              >
                                <span>Disponible sur commande</span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td
                          class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                        ></td>
                        <td
                          class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                          v-if="prixtab[index] != 'NOK'"
                        >
                          <span
                            class="material-icons-round cursor-pointer ml-4 text-blue-600"
                            @click="
                              searchTranslatedDirectAdd({
                                artNum: u.articleNo,
                                marque: u.brandNo,
                                marqueName: u.brandName,
                                plateform: this.user.plateform,
                              }),
                                (openModalSearch = false),
                                (openModalSearchVanHeck = false),
                                success()
                            "
                          >
                            add_shopping_cart
                          </span>
                        </td>
                        <td
                          class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                          v-else
                        >
                          --
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <RingLoader
                    color="#2d74da"
                    v-show="generalloading"
                    class="py-8 w-fit m-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- modal CarCat -->

        <div
          style="max-width: 50rem; max-height: 50rem"
          class="z-50 w-11/12 mx-auto bg-gray-200 rounded shadow-lg modal-container"
        >
          <div
            class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
          >
            <svg
              class="text-white fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
            <span class="text-sm">(Esc)</span>
          </div>

          <!-- Add margin if you want to see some of the overlay behind the modal-->
          <div class="px-6 py-4 text-left modal-content">
            <!--Title-->
            <div class="flex items-center justify-between pb-3">
              <p class="text-2xl font-bold">Références CarCat</p>
              <div
                class="z-50 cursor-pointer modal-close"
                @click="
                  (openModalSearchVanHeck = false), (openModalSearch = false)
                "
              >
                <svg
                  class="text-black fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <path
                    d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                  />
                </svg>
              </div>
            </div>

            <!-- partie pour CarCat -->
            <div
              class="flex flex-col mt-4"
              style="height: 80vh"
              v-if="tabRefVH.length > 0"
            >
              <div
                class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
              >
                <div
                  class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
                >
                  <table class="min-w-full">
                    <thead>
                      <tr>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Réf.
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Descr.
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Prix
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Stock
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        ></th>

                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        ></th>
                      </tr>
                    </thead>

                    <tbody class="bg-white">
                      <tr
                        v-for="(u, index) in tabRefVH"
                        :key="index"
                        class="hover:bg-gray-100"
                      >
                        <td
                          class="px-2 border-b border-gray-200 whitespace-nowrap tableTDTH"
                        >
                          <div class="flex flex-col">
                            <div class="flex flex-row items-center">
                              <div
                                class="space-x-1 flex text-sm leading-5 text-gray-900 font-medium"
                              >
                                <span
                                  class="material-icons-outlined m-auto cursor-pointer text-orange-500 hover:bg-gray-100 rounded hover:shadow hover:animate-spin"
                                  style="font-size: 11px"
                                  @click="
                                    searchCross(u.article[0].Ref_fournisseur),
                                      (open = !open),
                                      (searchInput =
                                        u.article[0].Ref_fournisseur)
                                  "
                                >
                                  cached
                                </span>
                                <span>{{ u.article[0].Ref_fournisseur }}</span>
                              </div>
                            </div>
                            <div class="text-sm leading-5 text-gray-500">
                              {{ u.article[0].Code_marque }}
                            </div>
                          </div>
                        </td>

                        <td
                          class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                        >
                          <div class="text-sm leading-5 text-gray-900">
                            {{ u.article[0].Description }}
                          </div>
                        </td>

                        <td
                          class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                        >
                          <span
                            v-if="u.article[0].Prix_euro"
                            class="text-sm leading-5 text-blue-500"
                            >{{
                              parseFloat(u.article[0].Prix_euro).toFixed(2)
                            }}
                            €</span
                          >
                        </td>

                        <td
                          class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                        >
                          <div class="flex space-x-1" v-if="prixtab[index]">
                            <!-- <img style="width:1rem" src="../assets/IconsDelivery/contrast.png"/>
                          <img style="width:1rem" src="../assets/IconsDelivery/day-and-night.png"/>
                          <img style="width:1rem" src="../assets/IconsDelivery/half-moon.png"/>
                          <img style="width:1rem" src="../assets/IconsDelivery/sun.png"/> -->
                            <div
                              v-if="prixtab[index] && prixtab[index].stock > 0"
                            >
                              <div v-if="u.stock[0]">
                                <span
                                  @mouseover="showIconDispoB[index] = true"
                                  @mouseleave="showIconDispoB[index] = false"
                                  style="width: 1.75rem; height: 1.75rem"
                                  class="material-icons-outlined p-0.5 rounded-full bg-green-500 text-white"
                                  >check_circle</span
                                >
                                <div
                                  class="absolute bg-gray-200 p-2 rounded shadow-lg z-20"
                                  v-show="showIconDispoB[index]"
                                >
                                  <span>{{ u.stock[0].stock }}</span>
                                  <span>pcs disponible</span>
                                </div>
                              </div>
                            </div>

                            <div>
                              <span
                                @mouseover="showIconFiliale1B[index] = true"
                                @mouseleave="showIconFiliale1B[index] = false"
                                style="width: 1.75rem; height: 1.75rem"
                                class="material-icons-outlined p-0.5 rounded-full bg-cyan-500 text-white"
                                >swap_horizontal_circle</span
                              >
                              <div
                                class="absolute bg-gray-200 p-2 rounded shadow-lg z-20"
                                v-show="showIconFiliale1B[index]"
                              >
                                <span>Disponible filiale 1</span>
                              </div>
                            </div>

                            <div>
                              <span
                                @mouseover="showIconFiliale2B[index] = true"
                                @mouseleave="showIconFiliale2B[index] = false"
                                style="width: 1.75rem; height: 1.75rem"
                                class="material-icons-outlined p-0.5 rounded-full bg-blue-500 text-white"
                                >swap_horizontal_circle</span
                              >
                              <div
                                class="absolute bg-gray-200 p-2 rounded shadow-lg z-20"
                                v-show="showIconFiliale2B[index]"
                              >
                                <span>Disponible filiale 2</span>
                              </div>
                            </div>

                            <div
                              v-if="
                                (prixtab[index] && prixtab[index].stock < 1) ||
                                !prixtab[index].stock
                              "
                            >
                              <span
                                @mouseover="showIconCmdB[index] = true"
                                @mouseleave="showIconCmdB[index] = false"
                                style="width: 1.75rem; height: 1.75rem"
                                class="material-icons-outlined p-0.5 rounded-full bg-orange-500 text-white"
                                >help_outline</span
                              >
                              <div
                                class="absolute bg-gray-200 p-2 rounded shadow-lg z-20"
                                v-show="showIconCmdB[index]"
                              >
                                <span>Disponible sur commande</span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td
                          class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                        ></td>
                        <td
                          class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                          v-if="prixtab[index] != 'NOK'"
                        >
                          <span
                            class="material-icons-round cursor-pointer ml-4 text-blue-600"
                            @click="
                              searchTranslatedDirectAdd({
                                artNum: u.article[0].Ref_fournisseur,
                                marqueName: u.article[0].Code_marque,
                                plateform: this.user.plateform,
                              }),
                                (openModalSearchVanHeck = false),
                                (openModalSearch = false),
                                success()
                            "
                          >
                            add_shopping_cart
                          </span>
                        </td>
                        <td
                          class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                          v-else
                        >
                          --
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <RingLoader
                    color="#2d74da"
                    v-show="generalloading"
                    class="py-8 w-fit m-auto"
                  />
                </div>
              </div>
            </div>
            <div v-else>Aucune référence trouvée</div>

            <!--Footer-->
            <!-- <div class="flex justify-end pt-2">
              <button
                @click="openModalSearchVanHeck = false"
                class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
              >
                Fermer
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- modal image ref -->
    <div
      v-if="openModalArticle"
      :class="`modal ${
        !openModalArticle && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="openModalArticle = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
        style="max-width: 72rem; max-height: 52rem"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <div class="flex items-center justify-end pb-3">
            <!-- <p class="text-2xl font-bold">Titre</p> -->
            <div
              class="z-50 cursor-pointer modal-close"
              @click="openModalArticle = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>
          <article-info :key="articleInfo_componentKey" />
        </div>
      </div>
    </div>

    <!-- Breadcrumb pour la commande -->
    <div
      v-if="openSave"
      :class="`modal ${
        !openSave && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="openSave = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        style="max-width: 30rem"
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Confirmation de la commande</p>
            <button @click="openSave = false" class="text-2xl">x</button>
          </div>

          <!-- BODY DASILVA -->
          <div
            v-if="
              plateform.structure_depot == true &&
              consigneData.length > 0 &&
              tabClient.numcomptedasilva &&
              tabClient.numcomptedasilva != ''
            "
            class="mt-6 mb-4"
          >
            <div class="flex justify-center">
              <div class="flex flex-col">
                <h1 class="">
                  N° compte DA SILVA : {{ tabClient.numcomptedasilva }}
                </h1>
                <div class="flex space-x-4 mt-6">
                  <h1>Facturation par :</h1>
                  <div class="flex">
                    <input
                      v-if="dsvCheck == false"
                      :value="true"
                      type="checkbox"
                      v-model="dsvCheck"
                      @change="setdsvcheck(true), setplateformcheck(false)"
                      class="w-5 h-5 m-auto accent-yellow-500 color-white"
                    />
                    <input
                      v-if="dsvCheck == true"
                      :value="false"
                      type="checkbox"
                      v-model="dsvCheck"
                      @change="setdsvcheck(false), setplateformcheck(true)"
                      class="w-5 h-5 m-auto accent-yellow-500 color-white"
                    />
                    <h1 class="ml-1">DA SILVA ?</h1>
                  </div>
                  <div class="flex">
                    <input
                      v-if="dsvCheck == true"
                      :value="true"
                      type="checkbox"
                      v-model="plateformCheck"
                      @change="setplateformcheck(true), setdsvcheck(false)"
                      class="w-5 h-5 m-auto accent-yellow-500 color-white"
                    />
                    <input
                      v-if="dsvCheck == false"
                      :value="false"
                      type="checkbox"
                      v-model="plateformCheck"
                      @change="setplateformcheck(false), setdsvcheck(true)"
                      class="w-5 h-5 m-auto accent-yellow-500 color-white"
                    />
                    <h1 class="ml-1">{{ user.plateform }} ?</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button
              v-if="!loadingCmd"
              @click="creerCmd()"
              @keyup.enter="creerCmd()"
              ref="validCmd"
              class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
            >
              Valider
            </button>
            <button
              v-else
              class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
            >
              <PulseLoader color="#ffff" />
              <span>En cours</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- modal promos  -->
    <div
      v-if="promoShow"
      :class="`modal ${
        !promoShow && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="promoShow = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        style="max-width: 72rem; max-height: 42rem"
        class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between mb-3">
            <p class="text-2xl font-bold">Promotions</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="promoShow = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                >
                  Référence
                </th>

                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                >
                  Quantité
                </th>

                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                >
                  Désignation
                </th>

                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                >
                  Remise
                </th>

                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                >
                  Prix net HT
                </th>
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr
                v-for="item in foundPromo"
                :key="item.id"
                @click="
                  setPrixQuantitatif(item.Quantite, item.PrixNet),
                    (promoShow = false)
                "
                class="cursor-pointer bg-vk1"
              >
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ item.Ref_fournisseur }} -
                  {{ item.Code_marque }}
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ item.Quantite }}
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ item.Designation }}
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ item.Remise }}
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(item.PrixNet).toFixed(2) }} €
                </td>
              </tr>
            </tbody>
          </table>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button
              @click="promoShow = false"
              class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Retour -->
    <div
      v-if="openRetour"
      :class="`modal ${
        !openRetour && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="openRetour = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
        style="max-width: 200rem"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p v-show="tabRetourHistorique" class="text-2xl font-bold">
              Retour article
            </p>
            <p v-show="!tabRetourHistorique" class="text-2xl font-bold">
              Récapitulatif
            </p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="openRetour = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->

          <div
            class="flex cursor-pointer rounded-md bg-orange-200 hover:bg-orange-300 w-20 shadow-md text-white"
            v-show="!tabRetourHistorique"
            @click="tabRetourHistorique = !tabRetourHistorique"
          >
            <span class="material-icons-round"> arrow_back </span>
            <span>retour</span>
          </div>
          <div>
            <div
              class="py-2 -my-2 mt-3 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 hover:bg-white"
              style="height: 50vh"
            >
              <div v-show="tabRetourHistorique">
                <div
                  class="inline-block h-fit min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg overflow-y-auto"
                >
                  <table class="min-w-full">
                    <thead>
                      <tr>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Doc n°
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Date
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Client
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Créé par
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Prix total
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Référence client
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white">
                      <tr
                        v-for="(historiqueFile, index) in historique"
                        :key="index"
                        @click="attachRetour(historiqueFile)"
                        class="cursor-pointer hover:bg-orange-200 hover:bg-opacity-25"
                      >
                        <td
                          class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                        >
                          {{ historiqueFile.type }} N°
                          {{ historiqueFile.Numero }}
                        </td>
                        <td
                          class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                        >
                          {{ getFRDate(historiqueFile.Date) }}
                        </td>
                        <td
                          class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                        >
                          {{ historiqueFile.Client }}
                        </td>
                        <td
                          class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                        >
                          {{ historiqueFile.Name }}
                        </td>
                        <td
                          class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                        >
                          {{ financial(historiqueFile.TotalPrice) }} €
                        </td>
                        <td
                          class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                        >
                          {{ historiqueFile.Note }}
                        </td>

                        <!-- <td class="px-6 py-3 border-b border-gray-200 bg-gray-50"></td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                v-show="!tabRetourHistorique"
                class="inline-block h-fit min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg overflow-y-auto"
              >
                <table class="min-w-full">
                  <thead>
                    <tr>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                      >
                        Qté
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                      >
                        Réf.
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                      >
                        Description
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                      >
                        Marque
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                      >
                        Prix ppc
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                      >
                        Prix acheté
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                      ></th>
                    </tr>
                  </thead>
                  <tbody class="bg-white">
                    <tr
                      v-for="(retourArticles, index) in retourSelected.Articles"
                      :key="index"
                    >
                      <td
                        v-if="retourArticles.Ref_fournisseur == retour_art"
                        class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                      >
                        <input
                          disabled
                          class="m-auto focus:outline-2 outline-sky-300 border border-green-600"
                          style="width: 6vh"
                          type="number"
                          :value="retourArticles.quantity"
                        />
                        {{ retourArticles.quantityReturned }}
                      </td>
                      <td
                        v-if="retourArticles.Ref_fournisseur == retour_art"
                        class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                      >
                        {{ retourArticles.Ref_fournisseur }}
                      </td>
                      <td
                        v-if="retourArticles.Ref_fournisseur == retour_art"
                        class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200"
                      >
                        {{ retourArticles.Description }}
                      </td>
                      <td
                        v-if="retourArticles.Ref_fournisseur == retour_art"
                        class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                      >
                        {{ retourArticles.Code_marque }}
                      </td>
                      <td
                        v-if="retourArticles.Ref_fournisseur == retour_art"
                        class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                      >
                        {{ financial(retourArticles.Prix_euro) }} €
                      </td>
                      <td
                        v-if="retourArticles.Ref_fournisseur == retour_art"
                        class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                      >
                        {{ financial(retourArticles.Prix_vente) }} €
                      </td>
                      <td
                        v-if="retourArticles.Ref_fournisseur == retour_art"
                        class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                      >
                        <div
                          class="flex justify-end space-x-4 py-4"
                          v-show="!tabRetourHistorique"
                        >
                          <input
                            class="focus:outline-2 outline-sky-300 border border-red-600"
                            style="width: 6vh"
                            type="number"
                            min="0"
                            :max="
                              retourArticles.quantity -
                              retourArticles.quantityReturned
                            "
                            :placeholder="
                              retourArticles.quantity -
                              retourArticles.quantityReturned
                            "
                            v-model="qtyReturned"
                          /><button
                            @click="addRetour(retourArticles)"
                            v-show="!tabRetourHistorique"
                            class="cursor-pointer text-xs px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
                          >
                            Valider
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <span v-show="showErrorMsgReturn" class="text-red-600"
              >Retour supérieur à l'achat !</span
            >
          </div>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button
              @click="
                (openRetour = false),
                  (tabRetourHistorique = !tabRetourHistorique)
              "
              class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Fin Modal Retour -->

    <div
      v-if="panierTransport"
      class="-mt-5 flex justify-end space-x-2 text-xs leading-5 text-gray-900"
    >
      <div class="flex flex-col text-right">
        <p>
          Total hors taxes des commandes jusqu'à {{ panierTransport.cutoff }} :
          {{ financial(panierTransport.BCtotalPrice) }} € /
          {{ financial(franco) }} €
        </p>
        <p v-if="panierTransport.shippingCosts === 0" class="text-green-600">
          Franco atteint ! Vous bénéficiez de la livraison gratuite pour les
          commandes passées jusqu'à {{ panierTransport.cutoff }}.
        </p>
        <p v-else class="text-blue-600">
          Franco non atteint ! Votre frais de port s'élève à
          {{ panierTransport.shippingCosts }} € pour les commandes passées
          jusqu'à {{ panierTransport.cutoff }}.
        </p>
      </div>
    </div>

    <!-- Tableau de recherche d'article -->
    <div class="mt-2 flex space-x-4">
      <div class="tableHEADTR relative block sm:mt-0">
        <!-- <input
          v-if="actionArticle.length > 0 && setTotal <= 0.0"
          disabled
          placeholder="Vous ne pouvez éditer qu'un retour à la fois"
          class="focus:outline-2 outline-sky-300 w-full py-2 pl-6 pr-12 text-sm text-gray-700 placeholder-gray-400 bg-white border border-b border-gray-400 rounded-l rounded-r appearance-none focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 my-auto"
        /> -->
        <input
          v-on:keyup.enter="getOneArticle()"
          v-model="articleSearch"
          placeholder="Entrez un code article"
          class="focus:outline-2 outline-sky-300 w-full py-2 pl-6 pr-12 text-sm text-gray-700 placeholder-gray-400 bg-white border border-b border-gray-400 rounded-l rounded-r appearance-none focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 my-auto"
        />
        <!-- <ul
          v-show="showAutoComplete"
          class="absolute z-20 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <li
            class="bg-white px-4 hover:bg-gray-300 cursor-pointer"
            v-for="(auto, index) in autoCompleteTab"
            :key="index"
            @click="setAutoComplete(auto.Ref_fournisseur)"
          >
            {{ auto.Ref_fournisseur }}
          </li>

          <div class="flex justify-end bg-white">
            <span
              class="material-icons-outlined rounded-full p-0.5 text-red-600 hover:bg-red-400 hover:bg-opacity-25 cursor-pointer"
              @click="showAutoComplete = false"
              >close</span
            >
          </div>
        </ul> -->
        <div
          v-show="showArticleList"
          class="absolute z-10 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <!-- Table pour trouver un produit -->
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Réf.
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Description
                </th>
                <th
                  class="px-5 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Dispo
                </th>
                <th
                  class="px-5 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-5 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Prix
                </th>
                <th
                  class="px-5 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>

                <th
                  class="px-5 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-5 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <button
                    class="cursor-pointer"
                    @click="showArticleList = false"
                  >
                    <span
                      class="material-icons-outlined rounded-full p-0.5 text-red-600 hover:bg-red-400 hover:bg-opacity-25"
                      >close</span
                    >
                  </button>
                </th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>
            <tbody class="bg-white" v-if="searchTab.length > 0">
              <tr
                @click="handlerArticle(csArticle)"
                class="bg-blue-100 hover:bg-opacity-25 cursor-pointer"
                style="max-height: 10px"
                v-for="(csArticle, index) in searchTab"
                :key="index"
              >
                <td class="px-6 border-b border-gray-200 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm leading-5 text-gray-900">
                        {{ csArticle.article.Ref_fournisseur }}
                      </div>
                    </div>
                  </div>
                </td>

                <td class="px-6 border-b border-gray-200 whitespace-nowrap">
                  <div class="text-sm leading-5 text-gray-900">
                    {{ csArticle.article.Code_marque }}
                  </div>
                  <div class="text-sm leading-5 text-gray-500">
                    {{ csArticle.article.Description }}
                  </div>
                </td>

                <td class="px-5 border-b border-gray-200 whitespace-nowrap">
                  <div v-if="csArticle.stockData.length > 0">
                    <span v-if="csArticle.stockData[0].stock <= 0"
                      >Sur commande</span
                    >
                    <span v-else>Dispo</span>
                  </div>
                  <span v-else>Sur commande</span>
                </td>
                <td
                  class="px-5 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                ></td>
                <td
                  class="px-5 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex flex-row space-x-2 items-center">
                    <span class="text-xs line-through"
                      >{{ financial(csArticle.prix.prix_brut) }} €</span
                    >
                    <span>{{ financial(csArticle.prix.prix_net) }} €</span>
                    <span
                      class="p-1 text-xs bg-orange-500 text-white rounded shadow animate-pulse"
                      v-if="csArticle.prix.promo"
                      >Promos</span
                    >
                  </div>
                </td>
                <td
                  class="px-5 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                ></td>

                <td
                  class="px-5 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                ></td>
                <td
                  class="px-5 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                ></td>
                <td
                  class="px-5 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                ></td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
              </tr>
            </thead>
          </table>
          <!-- Fin Table pour trouver un produit -->
        </div>

        <div
          class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
        >
          <svg
            class="w-4 h-4 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </div>
      </div>
      <div>
        <button
          v-if="!loadingExport"
          class="bg-green-600 text-white hover:bg-green-700 border border-green-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded flex justify-center items-center"
          @click="exportExcel()"
        >
          <span class="material-icons-outlined text-xl"> download </span>
        </button>
        <button
          v-else
          class="bg-green-600 text-white hover:bg-green-700 border border-green-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded flex justify-center items-center"
        >
          <PulseLoader color="#ffff" />
        </button>
      </div>
      <button
        @click="transferOnDevis()"
        class="flex justify-between space-x-2 w-fit px-4 py-1.5 bg-gray-50 hover:bg-gray-200 border border-gray-400 rounded-md m-auto"
        title="Éditer le panier en devis"
      >
        <span class="material-icons-outlined"> edit_note </span>
        <span>Devis</span>
      </button>
      <button
        class="px-6 py-2 ml-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
        @click="getQuestCmd()"
      >
        Commander
      </button>
      <!-- <button
          class="px-2 py-2 ml-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
          @click="sendRetour()"
          v-if="actionArticle.length > 0 && setTotal <= 0.0"
        >
          Demande de retour
        </button> -->
    </div>

    <!-- Tableau de comptoir -->

    <div class="flex flex-col mt-2">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead class="tableHEADTR">
              <tr>
                <th
                  class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Réf.
                </th>
                <th
                  class="mx-20 w-80 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Description
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Qté
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Dispo
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  P.Vente
                </th>
                <th
                  v-if="tabClient?.affichageRemise"
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="flex justify-between">
                    <div class="my-auto">Remise</div>
                    <div class="mt-0.5 m-auto cursor-pointer">
                      <div
                        v-if="!affichageRemise"
                        @click="affichageRemise = true"
                        title="Afficher les remises d'achat"
                      >
                        <span
                          class="material-icons-outlined text-blue-500 hover:text-blue-600"
                          >expand_more</span
                        >
                      </div>
                      <div
                        v-else
                        @click="affichageRemise = false"
                        title="Cacher les remises d'achat"
                      >
                        <span
                          class="material-icons-outlined text-blue-500 hover:text-blue-600"
                          >expand_less</span
                        >
                      </div>
                    </div>
                  </div>
                </th>

                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="flex justify-between">
                    <div class="my-auto">Px final</div>
                    <div class="mt-0.5 m-auto cursor-pointer">
                      <div
                        v-if="!affichagePxFinal"
                        @click="affichagePxFinal = true"
                        title="Afficher les informations"
                      >
                        <span
                          class="material-icons-outlined text-blue-500 hover:text-blue-600"
                        >
                          expand_more
                        </span>
                      </div>
                      <div
                        v-else
                        @click="affichagePxFinal = false"
                        title="Cacher les informations"
                      >
                        <span
                          class="material-icons-outlined text-blue-500 hover:text-blue-600"
                        >
                          expand_less
                        </span>
                      </div>
                    </div>
                  </div>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="flex justify-between">
                    <div class="my-auto">Total</div>
                    <div class="mt-0.5 m-auto cursor-pointer">
                      <div
                        v-if="!affichageTotal"
                        @click="affichageTotal = true"
                        title="Afficher les informations"
                      >
                        <span
                          class="material-icons-outlined text-blue-500 hover:text-blue-600"
                        >
                          expand_more
                        </span>
                      </div>
                      <div
                        v-else
                        @click="affichageTotal = false"
                        title="Cacher les informations"
                      >
                        <span
                          class="material-icons-outlined text-blue-500 hover:text-blue-600"
                        >
                          expand_less
                        </span>
                      </div>
                    </div>
                  </div>
                </th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>

            <tbody class="bg-white tableBODY">
              <tr
                v-for="(articleAct, index) in actionArticle"
                :key="index"
                class="tableHEADTR"
              >
                <td
                  class="px-6 w-44 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div class="flex flex-row space-x-2 items-center">
                    <div class="space-x-1 flex text-sm leading-5 text-gray-900">
                      <span
                        class="material-icons-outlined m-auto cursor-pointer text-orange-500 hover:bg-gray-100 rounded hover:shadow hover:animate-spin"
                        style="font-size: 11px"
                        @click="searchInputDirect(articleAct.Ref_fournisseur)"
                      >
                        sync
                      </span>
                      <span class="text-sm leading-5 text-gray-900">{{
                        articleAct.Ref_fournisseur
                      }}</span>
                    </div>
                    <span
                      v-if="articleAct.Code_marque == 'MEYLE'"
                      title="Plus d'informations"
                      style="font-size: 11px"
                      class="material-icons-outlined text-blue-500 cursor-pointer"
                      @click="
                        plusInfo(
                          articleAct.Ref_complementaire,
                          articleAct.Code_marque,
                          articleAct.Code_EAN,
                          articleAct.Prefixe_tarif
                        )
                      "
                    >
                      info
                    </span>
                    <span
                      v-else
                      title="Plus d'informations"
                      style="font-size: 11px"
                      class="material-icons-outlined text-blue-500 cursor-pointer"
                      @click="
                        plusInfo(
                          articleAct.Ref_fournisseur,
                          articleAct.Code_marque,
                          articleAct.Code_EAN,
                          articleAct.Prefixe_tarif
                        )
                      "
                    >
                      info
                    </span>
                    <span
                      v-if="articleAct.Promo_set"
                      class="material-icons-outlined animate-pulse ml-1 text-sm text-orange-500 cursor-pointer"
                      @click="
                        openPromo(
                          articleAct.Ref_fournisseur,
                          articleAct.Code_marque,
                          index
                        )
                      "
                    >
                      discount
                    </span>
                  </div>
                </td>

                <td
                  class="px-6 w-80 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  style="overflow: none"
                >
                  <div class="flex flex-col">
                    <div class="text-sm leading-5 text-gray-900">
                      {{ articleAct.Code_marque }}
                    </div>
                    <div
                      class="text-sm leading-5 text-gray-500 w-76 overflow-y-auto"
                    >
                      {{ articleAct.Description }}
                    </div>
                  </div>
                </td>
                <!--  @keyup.enter="
                      retourArticles(articleAct.quantity, articleAct, index)
                    " -->
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <input
                    v-if="
                      articleAct.prix_quantitatif &&
                      articleAct.prix_quantitatif == true
                    "
                    class="focus:outline-2 outline-sky-300 border"
                    v-model="articleAct.quantity"
                    :disabled="articleAct.disabled"
                    style="width: 30%"
                    type="number"
                    :min="articleAct.quantity_remise"
                    @change="
                      adaptConsigneQuantity(index),
                        checkPrixQuantitatif(
                          index,
                          articleAct.quantity,
                          articleAct.quantity_remise
                        )
                    "
                  />
                  <input
                    v-else
                    class="focus:outline-2 outline-sky-300 border"
                    v-model="articleAct.quantity"
                    :disabled="articleAct.disabled"
                    style="width: 30%"
                    type="number"
                    :min="articleAct.Qte_mini"
                    :placeholder="articleAct.Qte_mini"
                    @change="checkQty(index, articleAct.quantity)"
                  />
                  <span
                    class="inline-flex text-xs font-semibold leading-5 text-red-500 rounded-full"
                    style="cursor: pointer"
                    @click="deleteArticle(articleAct)"
                    v-if="articleAct.Ref_fournisseur != 'Frais de port'"
                  >
                    <span
                      class="material-icons-round bg-red-500 rounded-sm text-white"
                      style="font-size: small"
                    >
                      clear
                    </span>
                  </span>
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <stockvue
                    :Article="articleAct"
                    :quantity="articleAct.quantity"
                    :key="articleAct.Code_EAN + '-' + articleAct.quantity"
                  />
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <span
                    >{{ parseFloat(articleAct.Prix_euro).toFixed(2) }} €</span
                  >
                </td>

                <td
                  v-if="tabClient?.affichageRemise"
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div class="flex flex-col">
                    <div
                      v-if="affichageRemise"
                      class="flex space-x-4 text-sm leading-5"
                    >
                      <div v-if="articleAct.prix_quantitatif">Promo</div>
                      <div v-else>
                        <span
                          v-if="articleAct.PrixFournisseurType === 'remise'"
                        >
                          {{ articleAct.remise }} %
                        </span>
                        <span
                          v-else-if="
                            articleAct.PrixFournisseurType === 'prixnet'
                          "
                        >
                          prix net
                        </span>
                        <span v-else> {{ articleAct.remise }} % </span>
                      </div>
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div v-if="affichagePxFinal">
                    <span v-if="articleAct.PrixFournisseurType == 'prixnet'">
                      {{ parseFloat(articleAct.Prix_vente).toFixed(2) }}
                    </span>
                    <span v-else>
                      {{
                        (
                          parseFloat(articleAct.Prix_euro) -
                          parseFloat(articleAct.Prix_euro) *
                            (articleAct.remise / 100)
                        ).toFixed(2)
                      }}
                    </span>
                    €
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div v-if="affichageTotal">
                    <span v-if="articleAct.PrixFournisseurType == 'prixnet'">{{
                      (
                        parseFloat(articleAct.Prix_vente) * articleAct.quantity
                      ).toFixed(2)
                    }}</span>
                    <span v-else>{{
                      (
                        (parseFloat(articleAct.Prix_euro) -
                          parseFloat(articleAct.Prix_euro) *
                            (articleAct.remise / 100)) *
                        articleAct.quantity
                      ).toFixed(2)
                    }}</span>
                    €
                  </div>
                </td>
              </tr>
            </tbody>
            <thead class="tableHEADTR">
              <tr class="tableHEADTR">
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div
                    class="flex w-22 cursor-pointer items-center px-2 py-2 text-gray-600 border rounded-md hover:text-white hover:bg-blue-600 hover:opacity-40 m-auto"
                    @click="clearPanier()"
                  >
                    <span class="material-icons-outlined" style="color: red"
                      >delete_forever</span
                    >
                    <button class="text-xs mx-4">
                      <span class="font-bold" href="#">Vider</span>
                    </button>
                  </div>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="w-96 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="relative flex flex-col">
                    <span>Réf. client :</span>
                    <div
                      class="my-6 absolute inset-y-0 left-0 flex items-center px-1 text-gray-700 h-4"
                      @click="
                        (noteDocument = ''),
                          savedatadevis({ refClient: noteDocument })
                      "
                    >
                      <span
                        style="font-size: 15px"
                        class="material-icons-outlined cursor-pointer rounded-full bg-sky-200 hover:bg-sky-300 text-white hover:from-transparent"
                        >cancel</span
                      >
                    </div>
                    <input
                      class="block border rounded-md pl-5 pr-1 py-1 border-2 border-sky-200 focus:outline-2 outline-sky-300 text-sm font-bold uppercase"
                      v-model="noteDocument"
                      @input="savedatadevis({ refClient: noteDocument })"
                    />
                  </div>
                </th>
                <th
                  class="w-96 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="relative flex flex-col">
                    <span>Commentaire :</span>
                    <div
                      class="my-6 absolute inset-y-0 left-0 flex items-center px-1 text-gray-700 h-4"
                      @click="commentaire = ''"
                    >
                      <span
                        style="font-size: 15px"
                        class="material-icons-outlined cursor-pointer rounded-full bg-sky-200 hover:bg-sky-300 text-white hover:from-transparent"
                        >cancel</span
                      >
                    </div>
                    <textarea
                      class="block border rounded-md pl-5 pr-1 py-1 border-2 border-sky-200 focus:outline-2 outline-sky-300 text-sm font-bold uppercase"
                      v-model="commentaire"
                    ></textarea>
                  </div>
                </th>
                <th
                  class="w-4 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  {{ setQuantity }} pcs
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <!-- <div
                    v-if="panierTransport"
                    class="text-xs leading-5 text-gray-900"
                  >
                    Frais de port :
                    <span>{{ financial(setTransport) }} €</span>
                  </div>
                  <div class="text-xs leading-5 text-gray-900">
                    Franco de port :
                    <span>{{ financial(franco) }} €</span>
                  </div> -->
                </th>
                <th
                  class="w-4 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="w-64 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div v-if="!tabClient.autoEntrepreneurState" class="text-sm leading-5 text-gray-900">
                    Total HT :
                    {{ financial(setTotal) }} €
                  </div>
                  <div class="text-sm leading-5 text-gray-500">
                    Total TTC :
                    {{ financial(setTotalTTC) }} €
                  </div>
                </th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import RingLoader from "vue-spinner/src/RingLoader.vue";
import articleInfo from "../../components/articleInfo/articleInfo.vue";
import stockvue from "@/components/Article/stock.vue";
import {
  searchArticleClient,
  prepareArticleClient,
} from "@/hooks/searchEngine/article.ts";
import {
  getPanierTransport,
  manageTransportCart,
} from "@/hooks/transport/panierTransport.ts";
import { getFrDate } from "@/hooks/tools/date.ts";
import * as XLSX from "xlsx";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import {
  decrementStockIncrementReserved,
  reserveStock,
} from "@/hooks/stock/stock.ts";
import { getStockFournisseur } from "@/hooks/stock/stock.ts";
import { ORDER_KEYS } from "@/util/OrderSchemaConstants.js";
import { COMMON_KEYS } from "@/util/CommonSchemaConstants.js";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "CommandeF",
  data() {
    return {
      openSave: false,
      openRetour: false,
      showArticleList: false,
      tabRetourHistorique: true,
      articleSearch: "",
      foundArticle: [],
      documentType: "",
      titreDocument: "",
      noteDocument: "",
      commentaire: "",
      franco: "",
      fraisdeport: "",
      transport: "",
      historique: [],
      promoShow: false,
      tabPromo: [],
      indexArticlePromo: 0,
      prixRemise: 0,
      remiseArticle: 0,
      fournisseurs: [],
      clientTab: [],
      groupClient: "",
      tempRemiseFourn: [],
      numDocBC: 0,
      numDocBCConsigne: 0,
      retourSelected: [],
      autoCompleteTab: [],
      showAutoComplete: false,
      openModalSearchVanHeck: false,
      openModalSearch: false,
      openModalArticle: false,
      articleInfo_componentKey: 0,
      tabRefVH: [],
      showIconDispoA: [],
      showIconDispoB: [],
      showIconFiliale1A: [],
      showIconFiliale1B: [],
      showIconFiliale2A: [],
      showIconFiliale2B: [],
      showIconCmdA: [],
      showIconCmdB: [],
      tabPrixNetFourn: [],
      tabPrixNetFournClient: "",
      tabPrixNetClient: [],
      tempConsigne: [],
      foundPromo: [],

      totalPriceAllDay: 0.0,
      searchTab: [],
      qtyReturned: "",
      showErrorMsgReturn: false,
      quantityReturned: "",
      askedRetouArticle: [],

      panierTransport: {},
      totalDebut: 0.0,
      transportNonConsigne: 0,

      loadingExport: false,
      loadingCmd: false,

      affichageRemise: true,
      affichagePxFinal: true,
      affichageTotal: true,

      stateFilliale: null,
    };
  },
  components: {
    articleInfo,
    RingLoader,
    stockvue,
    PulseLoader,
  },
  computed: {
    ...mapGetters([
      "actionArticle",
      "user",
      "comptoirsearchtab",
      "carcatToken",
      "carCatLink",
      "carCatReference",
      "vehicule",
      // "tabDsv",
      // "tabArt",
      "crossoetab",
      "prixtab",
      "plateform",
      "generalloading",
      "tabClient",
      "consigneData",
      "consigneNonData",
      "dsvCheck",
      "plateformCheck",
      "refClient",
    ]),
    setTotal() {
      var total = 0.0;
      var totalRemise = 0.0;
      // var totalRemiseSupp = 0.0;
      var totaldebut = 0.0;
      if (this.actionArticle.length > 0) {
        this.actionArticle.forEach((element) => {
          if (element.PrixFournisseurType == "prixnet") {
            totalRemise = parseFloat(element.Prix_vente);
          } else {
            totalRemise =
              parseFloat(element.Prix_euro) -
              parseFloat(element.Prix_euro) * (element.remise / 100);
          }
          totaldebut = totaldebut + totalRemise * element.quantity;
          this.totalDebut = totaldebut;
        });

        // if (parseFloat(this.panierTransport.valeur) > 0.0) {
        //   if (
        //     parseFloat(this.panierTransport.valeur) + totaldebut <
        //     parseFloat(this.tabClient.francodeport)
        //   ) {
        //     total += totaldebut + parseFloat(this.tabClient.fraisdeport);
        //   } else {
        //     total += totaldebut;
        //     // this.transport = 0;
        //   }
        // } else {
        //   if (
        //     parseFloat(totaldebut) < parseFloat(this.tabClient.francodeport)
        //   ) {
        //     total += totaldebut + parseFloat(this.tabClient.fraisdeport);
        //   } else {
        total += totaldebut;
        //     // this.transport = 0;
        //   }
        // }
      } else {
        total += totaldebut;
      }

      return total;
    },
    setTotalTTC() {
      return this.setTotal + this.setTotal * 0.2;
    },
    // setTransport() {
    //   if (parseFloat(this.panierTransport.valeur) > 0.0) {
    //     if (
    //       parseFloat(this.panierTransport.valeur) + this.totalDebut <
    //       parseFloat(this.tabClient.francodeport)
    //     ) {
    //       return this.tabClient.fraisdeport;
    //     } else {
    //       return 0;
    //     }
    //   } else {
    //     if (this.totalDebut < parseFloat(this.tabClient.francodeport)) {
    //       return this.tabClient.fraisdeport;
    //     } else {
    //       return 0;
    //     }
    //   }
    // },
    setQuantity() {
      var qty = 0;
      this.actionArticle.forEach((element) => {
        qty = qty + parseFloat(element.quantity);
      });
      return qty;
    },
    setTodayDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;
      return today;
    },
    // Prix total ht des articles dasilva
    setTotalConsigne() {
      var total = 0.0;
      var totalRemise = 0.0;
      this.consigneData.forEach((element, index) => {
        totalRemise =
          parseFloat(element.Prix_euro) -
          parseFloat(element.Prix_euro) *
            (this.consigneData[index].remise / 100);
        total = total + totalRemise * this.consigneData[index].quantity;
      });
      return total;
    },
    // Prix total ttc des articles dasilva
    setTotalConsigneTTC() {
      return this.setTotalConsigne + this.setTotalConsigne * 0.2;
    },

    setTotalNonConsigne() {
      var total = 0.0;
      var totalRemise = 0.0;
      // var totalRemiseSupp = 0.0;
      var totaldebut = 0.0;
      // if (this.consigneNonData.length > 0) {
      this.consigneNonData.forEach((element) => {
        if (element.PrixFournisseurType == "prixnet") {
          totalRemise = parseFloat(element.Prix_vente);
        } else {
          totalRemise =
            parseFloat(element.Prix_euro) -
            parseFloat(element.Prix_euro) * (element.remise / 100);
        }
        totaldebut = totaldebut + totalRemise * element.quantity;
      });

      // } else {
      //   total += totaldebut;
      // }

      return totaldebut;
    },
    // getTrTotalNonConsigne() {
    //   var total = this.setTotalNonConsigne;
    //   if (parseFloat(this.panierTransport.valeur) > 0.0) {
    //     if (
    //       parseFloat(this.panierTransport.valeur) + total <
    //       parseFloat(this.tabClient.francodeport)
    //     ) {
    //       return {
    //         total: total + parseFloat(this.tabClient.fraisdeport),
    //         transport: this.tabClient.fraisdeport,
    //       };

    //       // total += total + parseFloat(this.tabClient.fraisdeport);
    //       // this.transportNonConsigne = this.tabClient.fraisdeport;
    //     } else {
    //       // total += total;
    //       // this.transportNonConsigne = 0;
    //       return { total: total, transport: 0 };
    //     }
    //   } else {
    //     if (total < parseFloat(this.tabClient.francodeport)) {
    //       return {
    //         total: total + parseFloat(this.tabClient.fraisdeport),
    //         transport: this.tabClient.fraisdeport,
    //       };
    //       // total += totaldebut + parseFloat(this.tabClient.fraisdeport);
    //       // this.transportNonConsigne = this.tabClient.fraisdeport;
    //     } else {
    //       // total += totaldebut;
    //       // this.transportNonConsigne = 0;
    //       return { total: total, transport: 0 };
    //     }
    //   }
    // },
  },
  methods: {
    ...mapActions([
      "addactionArticle",
      "suppactionArticle",
      "suppactionconsigne",
      "clearactionArticle",
      "updateactionArticle",
      "updatestockactionArticle",
      "updateremiseactionArticle",
      "searchTranslatedDirect",
      "searchTranslatedDirectAdd",
      "setprixquantitatif",
      "removeprixquantitatif",
      "storecarcattoken",
      "clearcarcattoken",
      "addpaniercarcat",
      "setcarcatlinks",
      "addarticledsv",
      "addarticlenondsv",
      "cleararticledsv",
      "cleararticlenondsv",
      "supparticledsv",
      "supparticlenondsv",
      "searchCrossOe",
      "searchCross",
      "searchDirect",
      "setArticleInfo",
      "adddatanonconsigne",
      "suppdatanonconsigne",
      "cleardatanonconsigne",
      "setdsvcheck",
      "setplateformcheck",
      "adddataconsigne",
      "updateremisedataconsigne",
      "updateqtydataconsigne",
      "suppdataconsigne",
      "cleardataconsigne",
      "getTabClient",
      "searchRefFournisseur",
      "adddatadevis",
      "savedatadevis",
      "cleardatadevis",
      "clearvehicule",
    ]),
    getFRDate(date) {
      return getFrDate(date);
    },
    async getPanierTransportClient() {
      this.panierTransport = await getPanierTransport(
        this.user.plateform,
        this.tabClient._id
      );
      console.log("PANIER TRANSPORT : ", this.panierTransport);
    },
    // async getPanierTransport() {
    //   this.panierTransport = await getPanierTransportByClient(
    //     this.user.plateform,
    //     this.user.proprietaire
    //   );
    // },
    // async updatePanierTransport(numero) {
    //   const updatePanier = await updatePanierTransportByClient(
    //     this.user.plateform,
    //     this.user.proprietaire,
    //     "Bon de commande",
    //     numero,
    //     this.totalDebut
    //   );
    // },
    prepareQuantity() {
      this.actionArticle.forEach((element, index) => {
        this.qtyTab[index] = element.quantity;
        if (element.PrixFournisseurType == "prixnet") {
          this.remiseTab[index] = 0;
        }
      });
    },
    adaptConsigneQuantity(index) {
      if (
        this.actionArticle[index + 1] &&
        this.actionArticle[index + 1].Ref_fournisseur.startsWith("C :")
      ) {
        this.actionArticle[index + 1].quantity =
          this.actionArticle[index].quantity;
      }
    },
    async deleteArticle(dataArt) {
      await this.suppactionArticle(dataArt._id);
      this.suppdataconsigne(dataArt._id);
      this.suppdatanonconsigne(dataArt._id);
      this.getGroupClient();
    },
    clearPanier() {
      this.clearactionArticle();
      this.cleardataconsigne();
      this.cleardatanonconsigne();
      this.setdsvcheck(false);
      this.setplateformcheck(false);
    },
    retourArticles(quantite, art, index) {
      if (quantite < 0) {
        this.openRetour = true;
        this.fetchHistoriqueRetour(art.Ref_fournisseur);
        this.retour_id = art._id;
        this.retour_art = art.Ref_fournisseur;
        this.retour_index = index;
      }
    },
    attachRetour(historiqueFileSelected) {
      this.retourSelected = [];
      this.tabRetourHistorique = false;
      this.retourSelected = historiqueFileSelected;
    },
    addRetour(articleRetourData) {
      if (
        this.qtyReturned >
        articleRetourData.quantity - articleRetourData.quantityReturned
      ) {
        this.showErrorMsgReturn = true;
      } else if (articleRetourData.Ref_fournisseur.startsWith("C :")) {
        this.showErrorMsgReturn = false;
        this.openRetour = false;
        this.tabRetourHistorique = !this.tabRetourHistorique;
        this.actionArticle[this.retour_index].Prix_euro =
          articleRetourData.Prix_euro;
        this.actionArticle[this.retour_index].Prix_vente =
          articleRetourData.Prix_vente;
        this.actionArticle[this.retour_index].PrixFournisseurType =
          articleRetourData.PrixFournisseurType;
        this.actionArticle[this.retour_index].remise = articleRetourData.remise;
        for (const art of this.actionArticle) {
          if (art.Ref_fournisseur != articleRetourData.Ref_fournisseur) {
            this.suppactionconsigne(art.Ref_fournisseur);
          } else {
            art.quantity = this.qtyReturned;
          }
        }
        this.qtyReturned = "";
        this.addactionArticle({
          _id: this.retour_id + "retour",
          Ref_fournisseur:
            "Retour consigne sur " +
            this.retourSelected.type +
            " n°" +
            this.retourSelected.Numero,
          Description: this.retourSelected.Date,
          commentaire: "",
          Prix_vente: 0,
          quantity: 1,
          remise: 0,
          Prix_euro: 0,
          PrixFournisseurType: this.retourSelected.PrixFournisseurType,
        });
      } else {
        this.showErrorMsgReturn = false;
        this.openRetour = false;
        this.tabRetourHistorique = !this.tabRetourHistorique;
        this.actionArticle[this.retour_index].Prix_euro =
          articleRetourData.Prix_euro;
        this.actionArticle[this.retour_index].Prix_vente =
          articleRetourData.Prix_vente;
        this.actionArticle[this.retour_index].PrixFournisseurType =
          articleRetourData.PrixFournisseurType;
        this.actionArticle[this.retour_index].remise = articleRetourData.remise;
        for (const art of this.actionArticle) {
          if (
            art.quantity > 0 &&
            articleRetourData.Montant_consigne != art.Montant_consigne &&
            !art.Ref_fournisseur.startsWith("C :")
          ) {
            this.suppactionArticle(art._id);
          } else {
            art.quantity = this.qtyReturned;
          }
        }
        this.qtyReturned = "";
        this.addactionArticle({
          _id: this.retour_id + "retour",
          Ref_fournisseur:
            "Retour pièce sur " +
            this.retourSelected.type +
            " n°" +
            this.retourSelected.Numero,
          Description: this.retourSelected.Date,
          commentaire: "",
          Prix_vente: 0,
          quantity: 1,
          remise: 0,
          Prix_euro: 0,
          PrixFournisseurType: this.retourSelected.PrixFournisseurType,
        });
      }
    },

    async sendRetour() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;
      let pdfData = [];
      for (const element of this.actionArticle) {
        pdfData.push({
          description: element.Description,
          ref: element.Ref_fournisseur,
          quantite: element.quantity,
          remise: element.remise,
          commentaire: "",
          remiseSupp: 0,
          prixU: element.Prix_euro - element.Prix_euro * (element.remise / 100),
          total: element.Prix_euro * element.quantity,
        });
      }
      //TODO : send retour
      if (this.actionArticle[0].Ref_fournisseur.startsWith("C :")) {
        await axios.post(`${process.env.VUE_APP_API}/retourConsigne`, {
          plateform: this.user.plateform,
          Client: this.user.proprietaire,
          Name: this.user.username,
          // Date: today,
          clientInfo: this.retourSelected.clientInfo,
          Articles: this.actionArticle,
          pdfData: pdfData,
          NumeroBl: parseInt(this.retourSelected.Numero),
          reglement: [],
          Note: this.retourSelected.Note,
          type: this.retourSelected.type,
          status: "Retour demandé",
          Archived: false,
          Totalretour: this.setTotal,
        });
        await this.ajustQuantityReturned(this.actionArticle);
      } else {
        await axios.post(`${process.env.VUE_APP_API}/retour`, {
          plateform: this.user.plateform,
          Client: this.user.proprietaire,
          Name: this.user.username,
          // Date: today,
          clientInfo: this.retourSelected.clientInfo,
          Articles: this.actionArticle,
          pdfData: pdfData,
          NumeroBl: parseInt(this.retourSelected.Numero),
          reglement: [],
          Note: this.retourSelected.Note,
          type: this.retourSelected.type,
          status: "Retour demandé",
          Archived: false,
          Totalretour: this.setTotal,
        });
        await this.ajustQuantityReturned(this.actionArticle);
      }
      this.clearPanier();
      this.articleSearch = "";
      this.commentaire = "";
      this.noteDocument = "";
      this.savedatadevis({ refCLient: "" });
      this.clearvehicule();
    },
    async ajustQuantityReturned(article) {
      if (this.retourSelected.type == "BL") {
        for (const [indexA, element] of article.entries()) {
          await axios.put(
            `${process.env.VUE_APP_API}/bdl/return/${this.retourSelected._id}`,
            {
              _id: element._id,
              Ref_fournisseur: element.Ref_fournisseur,
              quantityReturned: -element.quantity,
            }
          );
        }
      }
    },
    fetchHistoriqueRetour1(art) {
      this.askedRetouArticle = art;
      axios
        .post(`${process.env.VUE_APP_API}/bdl/getbyclient`, {
          uid: {
            client: this.user.proprietaire,
            owner: this.user.plateform,
            article: art,
          },
        })
        .then((response) => {
          this.historique = response.data;
        });
    },
    fetchHistoriqueRetour(art) {
      this.askedRetouArticle = art;
      axios
        .post(`${process.env.VUE_APP_API}/bdl/getbyclient`, {
          uid: {
            client: this.user.proprietaire,
            owner: this.user.plateform,
            article: art,
          },
        })
        .then((response) => {
          this.historique = response.data;
        });
    },
    async handlerArticle(structure) {
      var existArticle = false;

      for (const [index, actArticle] of this.actionArticle.entries()) {
        if (
          actArticle.Ref_fournisseur == structure.article.Ref_fournisseur &&
          actArticle.Prefixe_tarif == structure.article.Prefixe_tarif &&
          actArticle.Code_marque == structure.article.Code_marque
        ) {
          this.actionArticle[index].quantity =
            parseInt(this.actionArticle[index].quantity) + 1;
          existArticle = true;
          this.setRemiseArticle(
            index,
            this.actionArticle[index].remise,
            this.actionArticle[index].quantity,
            actArticle.Prix_euro,
            this.actionArticle[index]
          );
          this.checkQty(index, this.actionArticle[index].quantity);
        }
      }
      if (existArticle == false) {
        let plateform_mere = this.user.plateform;
        if (this.plateform.plateform_mere) {
          plateform_mere = this.plateform.plateform_mere;
        }
        var dataTab = await prepareArticleClient(
          structure,
          plateform_mere,
          this.tabClient.Name,
          this.tabClient.Group.Name,
          1
        );

        const dispoFournisseur = await getStockFournisseur(
          structure.article,
          structure.article.Ref_fournisseur,
          structure.article.Code_marque,
          structure.article.Prefixe_tarif,
          this.plateform.grossierID,
          this.quantity,
          this.plateform.grossierID,
          plateform_mere
        );
        this.stateFournisseur = dispoFournisseur.dispoFournisseur;
        if (dispoFournisseur.dispoFournisseur) {
          if (dispoFournisseur.fraisdeport) {
            if (dispoFournisseur.fraisdeport > 0) {
              var countArticle = 0;
              for (const [index, actArticle] of this.actionArticle.entries()) {
                if (
                  actArticle.Code_marque == structure.article.Code_marque &&
                  actArticle.Ref_fournisseur !=
                    "Transport " + structure.article.Code_marque
                ) {
                  countArticle++;
                }
              }
              if (countArticle % 5 == 0) {
                await this.addConsigne(dataTab);
                await this.addactionArticle(dataTab);
                this.addactionArticle({
                  _id: "fraisdeport" + structure.article.Code_marque,
                  Ref_fournisseur: "Transport " + structure.article.Code_marque,
                  Prix_euro: structure.article.fraisdeport,
                  Prix_vente: structure.article.fraisdeport,
                  PrixFournisseurType: "prixnet",
                  disabled: true,
                  quantity: 1,
                  remise: 0,
                  prixtotal: structure.article.fraisdeport,
                  prixfinal: structure.article.fraisdeport,
                  Code_marque: structure.article.Code_marque,
                  Description:
                    "Transport dépannage fournisseur " +
                    structure.article.Code_marque,
                  commentaire: "",
                });
              }
            } else {
              await this.addConsigne(dataTab);
              await this.addactionArticle(dataTab);
            }
          }
        }

        await this.addConsigne(dataTab);
        await this.addactionArticle(dataTab);
      }

      this.showArticleList = false;
      this.articleSearch = "";
      // this.addProduitComponent = false;
    },
    async getOneArticle() {
      this.showAutoComplete = false;
      if (this.articleSearch != "") {
        let plateform_mere = this.user.plateform;
        if (this.plateform.plateform_mere) {
          plateform_mere = this.plateform.plateform_mere;
        }
        this.searchTab = await searchArticleClient(
          this.articleSearch,
          plateform_mere,
          this.user.proprietaire,
          this.tabClient.Group.Name
        );

        this.showArticleList = true;
      }
    },
    async addConsigne(dataConsigne) {
      const foundConsigne = await axios.post(
        `${process.env.VUE_APP_API}/article/getconsigne`,
        {
          data: dataConsigne,
        }
      );
      if (foundConsigne.data.length > 0) {
        // if (this.dsvCheck == false) {
        this.addactionArticle({
          _id: dataConsigne._id,
          Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
          Prix_euro: dataConsigne.Montant_consigne,
          Prix_vente: dataConsigne.Montant_consigne,
          PrixFournisseurType: "prixnet",
          disabled: true,
          quantity: dataConsigne.quantity,
          quantityPrep: 0,
          quantityReturned: 0,
          remise: 0,
          prixtotal: dataConsigne.Prix_euro,
          prixfinal: dataConsigne.Prix_euro,
          Code_marque: dataConsigne.Code_marque,
          Description: dataConsigne.Description,
          commentaire: dataConsigne.commentaire,
        });
        // }
        if (dataConsigne.Code_marque == "DA SILVA") {
          this.adddataconsigne({
            _id: dataConsigne._id,
            Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
            Prix_euro: dataConsigne.Montant_consigne,
            Prix_vente: dataConsigne.Montant_consigne,
            PrixFournisseurType: "prixnet",
            disabled: true,
            quantity: dataConsigne.quantity,
            quantityPrep: 0,
            quantityReturned: 0,
            remise: 0,
            prixtotal: dataConsigne.Prix_euro,
            prixfinal: dataConsigne.Prix_euro,
            Code_marque: dataConsigne.Code_marque,
            Description: dataConsigne.Description,
            commentaire: dataConsigne.commentaire,
          });
          this.adddataconsigne(dataConsigne);
        } else {
          this.adddatanonconsigne({
            _id: dataConsigne._id,
            Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
            Prix_euro: dataConsigne.Montant_consigne,
            Prix_vente: dataConsigne.Montant_consigne,
            PrixFournisseurType: "prixnet",
            disabled: true,
            quantityPrep: 0,
            quantityReturned: 0,
            quantity: dataConsigne.quantity,
            remise: 0,
            prixtotal: dataConsigne.Prix_euro,
            prixfinal: dataConsigne.Prix_euro,
            Code_marque: dataConsigne.Code_marque,
            Description: dataConsigne.Description,
            commentaire: dataConsigne.commentaire,
          });
          this.adddatanonconsigne(dataConsigne);
        }
      } else {
        // if (
        //   this.tabClient.numcomptedasilva &&
        //   this.tabClient.numcomptedasilva != ""
        // ) {
        // }
        if (dataConsigne.Code_marque == "DA SILVA") {
          this.adddataconsigne(dataConsigne);
        } else {
          this.adddatanonconsigne(dataConsigne);
        }
      }
    },
    async getQuestCmd() {
      // await this.getPanierTransportClient();
      if (this.actionArticle.length == 0) {
        this.toast.warning("Panier vide !", {
          position: "bottom-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } else if (this.noteDocument == "") {
        this.toast.warning("Veuillez remplir le champ référence client !", {
          position: "bottom-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } else if (this.tabClient.commander == true) {
        this.openSave = true;
        this.$refs.validCmd.focus();
        if (
          this.consigneData.length > 0 &&
          this.tabClient.numcomptedasilva &&
          this.tabClient.numcomptedasilva != ""
        ) {
          this.setplateformcheck(true);
        }
        this.setdsvcheck(false);
        // this.conditionDaSilva = false;
        // this.actionArticle.forEach((art) => {
        //   if (art.Ref_fournisseur.startsWith("C : ")) {
        //     this.tabConsigne.push(art);
        //   }
        // });
      } else {
        this.toast.warning(
          "Contactez votre fournisseur pour pouvoir commander !",
          {
            position: "bottom-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );
      }
    },
    async creerCmd() {
      let totalHT = 0.0;
      this.loadingCmd = true;
      if (this.dsvCheck == true) {
        if (this.consigneData.length > 0) {
          await this.creerBCConsigne();
          totalHT = this.setTotalConsigne;
        }
        if (this.consigneNonData.length > 0) {
          await this.creerBCNonConsigne();
          totalHT = this.setTotalNonConsigne;
        }
      } else {
        await this.creerBC();
        totalHT = this.setTotal;
      }
      await manageTransportCart(
        this.user.plateform,
        this.tabClient._id,
        this.user.username,
        {
          type: "Bon de commande",
          created_here: "comptoir client",
          BCnumber: this.numDocBC.toString(),
          BCdate: new Date(),
          BCuser: this.user.username,
          BCtotalPrice: totalHT,
          BLnumber: "",
          BLdate: "",
          BLuser: "",
          BLtotalPrice: 0,
        }
      );
      await this.getPanierTransportClient();
      this.clearPanier();
      this.articleSearch = "";
      this.commentaire = "";
      this.noteDocument = "";
      this.savedatadevis({ refCLient: "" });
      this.clearvehicule();
      this.loadingCmd = false;
      this.openSave = false;
    },
    async creerBC() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;
      var pdfinfo = [];
      this.actionArticle.forEach(async (element, index) => {
        pdfinfo[index] = {
          description: element.Description,
          ref: element.Ref_fournisseur,
          quantite: element.quantity,
          remise: element.remise,
          commentaire: "",
          remiseSupp: 0,
          prixU: element.Prix_euro - element.Prix_euro * (element.remise / 100),
          total: element.Prix_euro * element.quantity,
        };
      });
      var fournisseur = "";
      if (
        this.consigneData.length > 0 &&
        this.tabClient.numcomptedasilva &&
        this.tabClient.numcomptedasilva != ""
      ) {
        fournisseur = this.user.plateform;
      } else {
        fournisseur = "";
      }

      await axios
        .post(`${process.env.VUE_APP_API}/commandes/getlast`, {
          uid: this.user.plateform,
        })
        .then((response) => {
          this.numDocBC = response.data + 1;
        });
      await axios.post(`${process.env.VUE_APP_API}/commandes`, {
        Name: this.user.username,
        Owner: this.user.plateform,
        // Date: today,
        Numero: this.numDocBC,
        TotalPrice: this.setTotal,
        TotalPriceTTC: this.setTotal + this.setTotal * 0.2,
        Client: this.user.proprietaire,
        IsProcessed: false,
        Note: this.noteDocument,
        Articles: this.actionArticle,
        pdfData: pdfinfo,
        clientInfo: this.clientTab,
        Transport: 0,
        Fournisseur: fournisseur,
      });

      for (const element of this.actionArticle) {
        if (element.Prefixe_tarif) {
          await reserveStock(
            this.user._id,
            this.user.plateform,
            element._id,
            element.Ref_fournisseur,
            element.Code_marque,
            element.Code_EAN,
            0,
            0,
            10,
            "",
            this.user.username,
            "BC n°" +
              this.numDocBC +
              " crée au comptoir par " +
              this.user.username,
            element.Prefixe_tarif,
            this.user.proprietaire,
            this.numDocBC,
            "BC",
            "comptoir client",
            element.quantity
          );

          // decrementStockIncrementReserved(
          //   element.Code_EAN,
          //   element.quantity,
          //   element.quantity,
          //   this.user.plateform,
          //   element.Ref_fournisseur,
          //   element.Prefixe_tarif,
          //   element.Code_marque,
          //   this.user.username,
          //   "Comptoir Client : Commande n°" +
          //     this.numDocBC +
          //     " : édité par : " +
          //     this.user.username +
          //     ". -" +
          //     element.quantity +
          //     " en stock, +" +
          //     element.quantity +
          //     " en réservé.",
          //   this.user.proprietaire,
          //   this.numDocBC
          // );
        }
      }
      await this.saveDocument(fournisseur);
      // await this.updatePanierTransport(this.numDocBC);
      // await this.getPanierTransportClient();
    },
    async saveDocument(fournisseur) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;
      await axios.post(`${process.env.VUE_APP_API}/commandesClient`, {
        [ORDER_KEYS.TYPE]: "INTERNAL",
        [ORDER_KEYS.ORDER_SOURCE]: "VEKTEUR",
        [ORDER_KEYS.ORDER_SOURCE_APPLICATION]: "VEKTEUR_CLIENT_COMPTOIR",
        [ORDER_KEYS.CLIENT_TYPE]: "PROFESSIONAL",
        [ORDER_KEYS.CLIENT_NAME]: this.user.proprietaire,
        [ORDER_KEYS.CLIENT_ID]: this.clientTab[0]._id
          ? this.clientTab[0]._id
          : "DOES NOT EXISTS",
        [COMMON_KEYS.CREATED_BY]: this.user.username,
        [COMMON_KEYS.UPDATED_BY]: this.user.username,
        [COMMON_KEYS.PLATFORM]: this.user.plateform,
        [ORDER_KEYS.TOTAL_PRICE_HT]: this.setTotal,
        [ORDER_KEYS.TOTAL_PRICE_TTC]: this.setTotalTTC,
        [ORDER_KEYS.CLIENT_NOTE]: this.noteDocument,
        [ORDER_KEYS.VENDOR_NOTE]: this.commentaire,
        [ORDER_KEYS.CLIENT_DETAILS]: this.clientTab,
        [ORDER_KEYS.BC_ID]: this.numDocBC,
        [ORDER_KEYS.ARTICLES]: this.actionArticle,
        [ORDER_KEYS.STATUS]: "CREATED",
        [ORDER_KEYS.STATUS_PERCENTAGE]: 10,
        [ORDER_KEYS.PREPARED_BY]: "personne",
        [ORDER_KEYS.ORDER_REFERENCE]: "",
        [ORDER_KEYS.DELIVERY_PRICE]: 0,
      });
      this.toast.success("Commande envoyée !", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    async creerBCConsigne() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;
      var pdfinfo = [];

      var clientCode = "";
      var bcType = "";

      if (this.setTotalConsigne >= 0) {
        bcType = "F";
      } else {
        bcType = "A";
      }
      if (this.dsvCheck == true) {
        clientCode = this.tabClient.numcomptedasilva;
      } else {
        clientCode = this.plateform.dasilva.codeClient;
      }
      this.consigneData.forEach((element, index) => {
        pdfinfo[index] = {
          description: element.Description,
          ref: element.Ref_fournisseur,
          quantite: element.quantity,
          remise: element.remise,
          commentaire: "",
          remiseSupp: 0,
          prixU: element.Prix_euro - element.Prix_euro * (element.remise / 100),
          total: element.Prix_euro * element.quantity,
        };
      });

      await axios
        .post(`${process.env.VUE_APP_API}/commandes/getlast`, {
          uid: this.user.plateform,
        })
        .then((response) => {
          this.numDocBCConsigne = response.data + 1 + "D";
        });
      await axios.post(`${process.env.VUE_APP_API}/commandes`, {
        Name: this.user.username,
        Owner: this.user.plateform,
        // Date: today,
        Numero: this.numDocBCConsigne,
        TotalPrice: this.setTotalConsigne,
        TotalPriceTTC: this.setTotalConsigne + this.setTotalConsigne * 0.2,
        Client: this.user.proprietaire,
        IsProcessed: false,
        Note: this.noteDocument,
        Articles: this.consigneData,
        pdfData: pdfinfo,
        clientInfo: this.clientTab,
        Transport: 0,
        Fournisseur: "DA SILVA",
        textFile: {
          consignataire: this.plateform.dasilva.consignataire,
          typeBL: bcType,
          codeClient: clientCode,
          numeroCmd: this.numDocBCConsigne,
          commentaire: "",
        },
      });

      for (const element of this.consigneData) {
        if (element.Prefixe_tarif) {
          await reserveStock(
            this.user._id,
            this.user.plateform,
            element._id,
            element.Ref_fournisseur,
            element.Code_marque,
            element.Code_EAN,
            0,
            0,
            10,
            "",
            this.user.username,
            "BC n°" +
              this.numDocBCConsigne +
              " crée au comptoir par " +
              this.user.username,
            element.Prefixe_tarif,
            this.user.proprietaire,
            this.numDocBCConsigne,
            "BC",
            "comptoir client",
            element.quantity
          );

          // decrementStockIncrementReserved(
          //   element.Code_EAN,
          //   element.quantity,
          //   element.quantity,
          //   this.user.plateform,
          //   element.Ref_fournisseur,
          //   element.Prefixe_tarif,
          //   element.Code_marque,
          //   this.user.username,
          //   "Comptoir Client : Commande n°" +
          //     this.numDocBCConsigne +
          //     " : édité par : " +
          //     this.user.username +
          //     ". -" +
          //     element.quantity +
          //     " en stock, +" +
          //     element.quantity +
          //     " en réservé.",
          //   this.user.proprietaire,
          //   this.numDocBCConsigne
          // );
        }
      }
      await this.saveDocumentConsigne();
    },
    async saveDocumentConsigne() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;
      var clientCode = "";
      var bcType = "";

      if (this.setTotalConsigne >= 0) {
        bcType = "F";
      } else {
        bcType = "A";
      }
      if (this.dsvCheck == true) {
        clientCode = this.tabClient.numcomptedasilva;
      } else {
        clientCode = this.plateform.dasilva.codeClient;
      }
      await axios.post(`${process.env.VUE_APP_API}/commandesClient`, {
        [ORDER_KEYS.TYPE]: "EXTERNAL",
        [ORDER_KEYS.ORDER_SOURCE]: "DA SILVA",
        [ORDER_KEYS.ORDER_SOURCE_APPLICATION]: "VEKTEUR_CLIENT_COMPTOIR",
        [ORDER_KEYS.CLIENT_TYPE]: "PROFESSIONAL",
        [ORDER_KEYS.CLIENT_NAME]: this.user.proprietaire,
        [ORDER_KEYS.CLIENT_ID]: this.clientTab[0]._id
          ? this.clientTab[0]._id
          : "DOES NOT EXISTS",
        [COMMON_KEYS.CREATED_BY]: this.user.username,
        [COMMON_KEYS.UPDATED_BY]: this.user.username,
        [COMMON_KEYS.PLATFORM]: this.user.plateform,
        [ORDER_KEYS.TOTAL_PRICE_HT]: this.setTotalConsigne,
        [ORDER_KEYS.TOTAL_PRICE_TTC]: this.setTotalConsigneTTC,
        [ORDER_KEYS.CLIENT_NOTE]: this.noteDocument,
        [ORDER_KEYS.VENDOR_NOTE]: this.commentaire,
        [ORDER_KEYS.CLIENT_DETAILS]: this.clientTab,
        [ORDER_KEYS.BC_ID]: this.numDocBCConsigne,
        [ORDER_KEYS.ARTICLES]: this.consigneData,
        [ORDER_KEYS.STATUS]: "CREATED",
        [ORDER_KEYS.STATUS_PERCENTAGE]: 10,
        [ORDER_KEYS.PREPARED_BY]: "personne",
        [ORDER_KEYS.ORDER_REFERENCE]: "",
        [ORDER_KEYS.DELIVERY_PRICE]: 0,
      });
      this.toast.success("Commande dsv envoyée !", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    async creerBCNonConsigne() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;
      var pdfinfo = [];

      var total = this.setTotalNonConsigne;
      var totalTTC = this.setTotalNonConsigne + this.setTotalNonConsigne * 0.2;
      // var transport = this.transportNonConsigne;

      this.consigneNonData.forEach((element, index) => {
        pdfinfo[index] = {
          description: element.Description,
          ref: element.Ref_fournisseur,
          quantite: element.quantity,
          remise: element.remise,
          commentaire: "",
          remiseSupp: 0,
          prixU: element.Prix_euro - element.Prix_euro * (element.remise / 100),
          total: element.Prix_euro * element.quantity,
        };
      });

      await axios
        .post(`${process.env.VUE_APP_API}/commandes/getlast`, {
          uid: this.user.plateform,
        })
        .then((response) => {
          this.numDocBC = response.data + 1;
        });
      await axios.post(`${process.env.VUE_APP_API}/commandes`, {
        Name: this.user.username,
        Owner: this.user.plateform,
        // Date: today,
        Numero: this.numDocBC,
        TotalPrice: total,
        TotalPriceTTC: total + total * 0.2,
        Client: this.user.proprietaire,
        IsProcessed: false,
        Note: this.noteDocument,
        Articles: this.consigneNonData,
        pdfData: pdfinfo,
        clientInfo: this.clientTab,
        Transport: 0,
        Fournisseur: "DA SILVA",
        textFile: {
          consignataire: "",
          typeBL: "",
          codeClient: "",
          numeroCmd: "",
          commentaire: "",
        },
      });

      for (const element of this.consigneNonData) {
        if (element.Prefixe_tarif) {
          await reserveStock(
            this.user._id,
            this.user.plateform,
            element._id,
            element.Ref_fournisseur,
            element.Code_marque,
            element.Code_EAN,
            0,
            0,
            10,
            "",
            this.user.username,
            element.Description,
            element.Prefixe_tarif,
            this.user.proprietaire,
            this.numDocBC,
            "BC",
            "comptoir client",
            element.quantity
          );

          // decrementStockIncrementReserved(
          //   element.Code_EAN,
          //   element.quantity,
          //   element.quantity,
          //   this.user.plateform,
          //   element.Ref_fournisseur,
          //   element.Prefixe_tarif,
          //   element.Code_marque,
          //   this.user.username,
          //   "Comptoir Client : Commande n°" +
          //     this.numDocBC +
          //     " : édité par : " +
          //     this.user.username +
          //     ". -" +
          //     element.quantity +
          //     " en stock, +" +
          //     element.quantity +
          //     " en réservé.",
          //   this.user.proprietaire,
          //   this.numDocBC
          // );
        }
      }
      await this.saveDocumentNonConsigne(total, totalTTC);
      // await this.updatePanierTransport(this.numDocBC);
      // await this.getPanierTransportClient();
    },
    async saveDocumentNonConsigne(total, totalTTC) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;

      await axios.post(`${process.env.VUE_APP_API}/commandesClient`, {
        [ORDER_KEYS.TYPE]: "EXTERNAL",
        [ORDER_KEYS.ORDER_SOURCE]: "DA SILVA",
        [ORDER_KEYS.ORDER_SOURCE_APPLICATION]: "VEKTEUR_CLIENT_COMPTOIR",
        [ORDER_KEYS.CLIENT_TYPE]: "PROFESSIONAL",
        [ORDER_KEYS.CLIENT_NAME]: this.user.proprietaire,
        [ORDER_KEYS.CLIENT_ID]: this.clientTab[0]._id
          ? this.clientTab[0]._id
          : "DOES NOT EXISTS",
        [COMMON_KEYS.CREATED_BY]: this.user.username,
        [COMMON_KEYS.UPDATED_BY]: this.user.username,
        [COMMON_KEYS.PLATFORM]: this.user.plateform,
        [ORDER_KEYS.TOTAL_PRICE_HT]: total,
        [ORDER_KEYS.TOTAL_PRICE_TTC]: totalTTC,
        [ORDER_KEYS.CLIENT_NOTE]: this.noteDocument,
        [ORDER_KEYS.VENDOR_NOTE]: this.commentaire,
        [ORDER_KEYS.CLIENT_DETAILS]: this.clientTab,
        [ORDER_KEYS.BC_ID]: this.numDocBC,
        [ORDER_KEYS.ARTICLES]: this.consigneNonData,
        [ORDER_KEYS.STATUS]: "CREATED",
        [ORDER_KEYS.STATUS_PERCENTAGE]: 10,
        [ORDER_KEYS.PREPARED_BY]: "personne",
        [ORDER_KEYS.ORDER_REFERENCE]: "",
        [ORDER_KEYS.DELIVERY_PRICE]: 0,
      });
      this.toast.success("Commande envoyée !", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    setHistoriqueFile(historiquedata) {
      this.updateactionArticle(historiquedata);
    },
    setStockArticle(ind, qty) {
      const dataArt = { indexArt: ind, quantity: qty };
      this.updatestockactionArticle(dataArt);
    },
    setRemiseArticle(ind, rem, qty, prix, article) {
      if (article.prix_quantitatif && article.prix_quantitatif == true) {
        if (qty < article.quantity_remise) {
          this.removeprixquantitatif({ index: ind });
          this.getGroupClient();
        }
      }
      const PrixFinal = parseFloat(prix) - parseFloat(prix) * (rem / 100);
      const prixTotal = PrixFinal * qty;
      const dataArt = {
        indexArt: ind,
        quantity: qty,
        remise: rem,
        prixtotal: prixTotal,
        prixfinal: PrixFinal,
      };
      this.updateremiseactionArticle(dataArt);
    },
    getGroupClient() {
      axios
        .post(
          `${process.env.VUE_APP_API}/client/getone/${this.user.proprietaire}`,
          {
            plateform: this.user.plateform,
          }
        )
        .then((response) => {
          this.clientTab = response.data;
          this.groupClient = response.data[0].Group.Name;
          if (response.data[0].professionnel) {
            this.franco = response.data[0].francodeport;
            // if (response.data[0].fraisdeport.length > 0) {
            //   this.fraisdeport = response.data[0].fraisdeport;
            //   this.transport = this.fraisdeport;
            // } else {
            //   this.fraisdeport = "0";
            //   this.transport = "0";
            // }
          }
        });
    },
    openPromo(reference, marque, index) {
      this.promoShow = true;
      this.indexArticlePromo = index;
      axios
        .post(`${process.env.VUE_APP_API}/promo/getone`, {
          promoinfo: {
            plateformID: this.user.plateform,
            Ref_fournisseur: reference,
            Code_marque: marque,
          },
        })
        .then((res) => {
          this.foundPromo = res.data;
        });
    },
    setPrixQuantitatif(quantite, prix) {
      if (
        this.actionArticle[this.indexArticlePromo].prix_quantitatif &&
        this.actionArticle[this.indexArticlePromo].prix_quantitatif == true
      ) {
        this.removeprixquantitatif({ index: this.indexArticlePromo });
      }
      var remise_de_depart = this.actionArticle[this.indexArticlePromo].remise;
      this.actionArticle[this.indexArticlePromo].quantity = quantite;
      this.actionArticle[this.indexArticlePromo].remise = 0;
      this.setprixquantitatif({
        index: this.indexArticlePromo,
        quantite: quantite,
        prix: prix,
        remise_de_depart: remise_de_depart,
      });
    },
    setRemisePromo(remise) {
      if (
        this.actionArticle[this.indexArticlePromo].prix_quantitatif &&
        this.actionArticle[this.indexArticlePromo].prix_quantitatif == true
      ) {
        this.removeprixquantitatif({ index: this.indexArticlePromo });
      }
      this.actionArticle[this.indexArticlePromo].remise = remise;
    },
    checkPrixQuantitatif(index, quantity, quantity_remise) {
      if (quantity < quantity_remise) {
        this.removeprixquantitatif({ index: index });
      }
    },
    checkQty(index, quantity) {
      if (
        this.actionArticle[index + 1] &&
        this.actionArticle[index + 1].Ref_fournisseur.startsWith("C :")
      ) {
        this.actionArticle[index + 1].quantity = quantity;
      }
    },
    transferOnDevis() {
      this.cleardatadevis();
      this.actionArticle.forEach((element) => {
        this.adddatadevis({ data: element, data2: true });
      });
      this.clearPanier();
      this.$router.push("./devisClient");
      this.toast.success("Panier transféré !", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    closeCarCat() {
      axios
        .post(
          `https://lkq-europe-prod.apigee.net/gms-api-public-iframe-routes-fr/iframe/retrieve-orders`,
          {
            IDToken: this.carcatToken.id_token,
            ReferenceIdentifier: this.carCatReference,
            PerPage: 1000,
            Page: 0,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.carcatToken.access_token}`,
              "X-api-key": "IbW4A7bqa447M2c7WhFwagVtbMjL9DLd",
            },
          }
        )
        .then((result) => {
          result.data.Data.Workorders[0].Lines.forEach((element) => {
            this.addpaniercarcat(element.LineCode);
          });
        })
        .catch(() => {
          this.clearcarcattoken();
        });
    },
    getRefSimilaire(ref) {
      axios
        .post(`${process.env.VUE_APP_API}/vanheck/getRefSimilaire`, {
          Ref_fournisseur: ref,
          plateform: this.user.plateform,
        })
        .then((response) => {
          this.tabRefVH = response.data;

          this.showCatRef = false;
        })
        .catch(() => {
          this.tabRefVH = [];
        });
    },
    searchInputDirect(modelInput) {
      this.searchRefFournisseur({ Ref_fournisseur: modelInput });
    },
    plusInfo(Ref, code, ean, prefixe) {
      this.articleInfo_componentKey++;
      this.openModalArticle = true;
      this.setArticleInfo({
        Ref_fournisseur: Ref,
        Code_marque: code,
        Code_EAN: ean,
        Prefixe_tarif: prefixe,
      });
    },
    autoComplete(ref) {
      if (this.articleSearch != "") {
        axios
          .get(`${process.env.VUE_APP_API}/article/autocomplete/${ref}`)
          .then((response) => {
            this.showAutoComplete = true;
            this.autoCompleteTab = response.data;
          });
      } else {
        this.showAutoComplete = false;
      }
    },
    setAutoComplete(ref) {
      this.articleSearch = ref;
      this.showAutoComplete = false;
      this.getOneArticle();
      this.showAjoutArticle = false;
      this.showArticleList = true;
    },
    async exportExcel() {
      this.loadingExport = true;
      const filteredData = this.actionArticle.map((item) => {
        const prixfinal =
          item.PrixFournisseurType === "prixnet"
            ? parseFloat(item.Prix_vente).toFixed(2)
            : (
                parseFloat(item.Prix_euro) -
                parseFloat(item.Prix_euro) * (item.remise / 100)
              ).toFixed(2);
        const total =
          item.PrixFournisseurType === "prixnet"
            ? (parseFloat(item.Prix_vente) * item.quantity).toFixed(2)
            : (
                (parseFloat(item.Prix_euro) -
                  parseFloat(item.Prix_euro) * (item.remise / 100)) *
                item.quantity
              ).toFixed(2);

        let remise;
        if (this.tabClient.affichageRemise) {
          if (item.prix_quantitatif && item.prix_quantitatif === true) {
            remise = "Promo";
          } else {
            remise =
              item.PrixFournisseurType === "remise"
                ? item.remise + " %"
                : item.PrixFournisseurType === "prixnet"
                ? "prixnet"
                : item.remise + " %";
          }
        } else {
          remise = "";
        }

        return {
          Code_EAN: item.Code_EAN,
          Ref_fournisseur: item.Ref_fournisseur,
          Description: item.Description,
          Code_marque: item.Code_marque,
          Quantité: item.quantity,
          Prix_vente: parseFloat(item.Prix_euro).toFixed(2),
          Remise: remise,
          Prix_final: prixfinal,
          Total: total,
        };
      });

      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.json_to_sheet(filteredData);
      XLSX.utils.book_append_sheet(wb, ws, "Mon Panier " + this.user.plateform);

      XLSX.writeFile(wb, "Liste_panier_" + this.user.proprietaire + ".xlsx");
      this.loadingExport = false;

      this.toast.success("Le fichier a été téléchargé !", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
    success() {
      this.toast.success("Ajouté au panier !", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  async mounted() {
    await this.getPanierTransportClient();
    // await this.getPanierTransport();
    await this.getTabClient();
    // this.transport = this.tabClient.fraisdeport;
    this.getGroupClient();
    if (this.refClient != "") {
      this.noteDocument = this.refClient;
    } else if (this.vehicule.length > 0) {
      this.noteDocument =
        this.vehicule[0].data.AWN_immat +
        " " +
        this.vehicule[0].data.AWN_modele;
    }
  },
};
</script>

<style scoped>
.modal {
  transition: opacity 0.25s ease;
}

.tableTDTH {
  text-align: left;
  padding: 8px;
}

.tableBODY {
  display: block;
  height: 70vh;
  overflow: auto;
}

.tableHEADTR {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.bg-vk {
  background-color: #2262b3;
}

.bg-vk1:hover {
  background-color: #ff914d;
  color: white;
}

.bg-vk2:hover {
  background-color: #2262b3;
  color: white;
}

.text-vk-blue {
  color: #2262b3;
  border: 1px solid #2262b3;
}
</style>
