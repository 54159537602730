<template>
  <!-- <div class="inline-flex items-center justify-center w-full px-14 mb-6 relative ">
    <hr class="w-full my-4 border-orange-400 border-1 rounded dark:bg-gray-700 ">
    <div class="absolute px-4 -translate-x-1/2 bg-gray-100 left-1/2 dark:bg-gray-900 text-xl font-bold text-darkblue">
      Arrivages et promotions en cours
    </div>
  </div> -->

  <div class="">
    <div class="mx-5 flex flex-col sm:flex-row mt-3 gap-6">
      <!-- Doc en cours -->
      <div
        class="w-full md:w-2/5 sm:block md:block lg:block bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-100 dark:border-gray-300"
      >
        <div class="flex flex-col px-1 mt-2">
          <!-- Commandes -->
          <div class="px-3 py-2 border-b">
            <div class="flex flex-col items-center">
              <img
                src="../assets/dashboard/Commandes.png"
                alt="Pictogramme commandes"
                class="w-14 hidden 2xl:block"
              />
              <h5
                class="text-xl font-medium text-gray-400 w-full text-center mt-1"
              >
                Commandes en cours
              </h5>
              <router-link to="/listeCommandes">
                <div
                  class="flex flex-col text-center mt-1 hover:scale-110 duration-300 hover:text-gray-600"
                >
                  <span class="text-bluevk font-bold text-6xl">{{
                    orderInProgress
                  }}</span>
                </div>
              </router-link>
            </div>
          </div>

          <!-- Retours -->
          <div class="px-3 py-2 border-b">
            <div class="flex flex-col items-center">
              <img
                src="../assets/dashboard/RetourCommandes.png"
                alt="Pictogramme retours"
                class="w-14 hidden 2xl:block"
              />
              <h5
                class="text-xl font-medium text-gray-400 w-full text-center mt-1"
              >
                Retours en cours
              </h5>
              <router-link to="/retour">
                <div
                  class="flex flex-col text-center mt-1 hover:scale-110 duration-300 hover:text-gray-600"
                >
                  <span class="text-bluevk font-bold text-6xl">{{
                    retourInProgress
                  }}</span>
                </div>
              </router-link>
            </div>
          </div>

          <!-- Infos -->
          <div class="mt-2 rounded-lg px-3 py-2 border border-gray-400 h-full">
            <h5 class="font-medium text-bluevk w-full text-left">
              Informations sur MAJ tarifs
            </h5>
            <hr class="md:my-1 border-t border-bluevk" />

            <div class="flex flex-col mt-2 text-sm overflow-y-auto">
              <h1 class="">Aucune information !</h1>
            </div>
          </div>
        </div>
      </div>

      <!-- Actualités -->
      <div
        v-if="plateform.dashboardVideo && plateform.dashboardVideo != ''"
        class="sm:w-3/5 border border-gray-200 shadow rounded-lg"
      >
        <hlsPlayer :videoSrc="plateform.dashboardVideo" ref="myVideo" />
      </div>
      <div
        v-else
        class="xs:hidden sm:block sm:w-/5 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-100 dark:border-gray-300"
      ></div>

      <!-- Historique -->
      <div
        class="items-center w-full md:w-2/5 sm:hidden md:block lg:block bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-100 dark:border-gray-300"
      >
        <div class="flex flex-col space-y-2 mt-2">
          <!-- Premier pavé -->
          <div class="rounded-lg mx-3 h-full">
            <router-link to="/historiqueArticle">
              <h5
                class="font-medium text-bluevk w-full text-left hover:scale-110 duration-300 cursor-pointer"
              >
                Historique Articles
              </h5>
            </router-link>
            <hr class="md:my-1 border-t border-bluevk" />
            <div class="flex flex-col">
              <!-- <div
                class="bg-white rounded-lg p-1 mt-1"
                v-for="(histArt, index) in histArtTab"
                :key="index"
              >
                <div class="flex justify-between">
                  <div class="flex items-center">
                    <img
                      src=""
                      alt=" "
                      class="my-auto bg-gray-300 rounded-sm ml-1 mr-2 text-xs text-gray-700 items-center"
                    />
                    <div class="my-auto">
                      <div
                        v-if="histArt.Search_data.length > 0"
                        class="text-xs font-bold text-bluevk"
                        :title="histArt.Search_data[0].article.Description"
                      >
                        {{
                          histArt.Search_data[0].article.Description.slice(
                            0,
                            10
                          )
                        }}
                        ... |
                        {{ histArt.Search_data[0].article.Code_marque }}
                      </div>
                      <div v-else class="text-xs font-bold text-bluevk">-</div>
                      <div
                        v-if="histArt.Date && histArt.Date != ''"
                        class="text-xs font-light text-gray-800"
                      >
                        {{ getFRDate(histArt.Date) }}
                      </div>
                      <div v-else class="text-xs font-light text-gray-800">
                        -
                      </div>
                      <div
                        v-if="histArt.Search && histArt.Search != ''"
                        class="text-xs font-medium text-orange-600"
                      >
                        {{ histArt.Search }}
                      </div>
                      <div v-else class="text-xs font-medium text-orange-600">
                        -
                      </div>
                    </div>
                  </div>
                  <div class="flex space-x-1">
                    <button
                      v-if="
                        histArt.Result.articleTab.length > 0 &&
                        histArt.Result.articleTab[0].stock.length > 0 &&
                        parseInt(histArt.Result.articleTab[0].stock[0].stock) >
                          0
                      "
                      class="material-icons-round mr-4 font-bold text-green-600 hover:text-green-700 hover:animate-pulse"
                      style="font-size: 16px"
                      title="disponible"
                    >
                      check_circle
                    </button>
                    <button
                      v-else-if="
                        histArt.Result.articleTab.length > 0 &&
                        histArt.Result.articleTab[0].stock.length > 0 &&
                        parseInt(histArt.Result.articleTab[0].stock[0].stock) <=
                          0
                      "
                      class="material-icons-round mr-4 font-bold text-blue-600 hover:text-blue-700 hover:animate-pulse"
                      style="font-size: 16px"
                      title="sur commande"
                    >
                      help
                    </button>
                    <button
                      v-else
                      class="material-icons-round mr-4 font-bold text-blue-600 hover:text-blue-700 hover:animate-pulse"
                      style="font-size: 16px"
                      title="sur commande"
                    >
                      help
                    </button>
                    <button
                      v-if="histArt.Search != ''"
                      class="material-icons-round hover:text-gray-600 hover:animate-pulse"
                      style="font-size: 16px"
                      title="Rechercher la référence"
                      @click="
                        searchRefFournisseur({
                          Ref_fournisseur: histArt.Search,
                        })
                      "
                    >
                      search
                    </button>
                    <button
                      v-if="histArt.Result.articleTab.length > 0"
                      class="material-icons-round text-orange-600 hover:text-orange-700 hover:animate-pulse"
                      style="font-size: 16px"
                      title="Ajouter au panier"
                      @click="
                        ajoutArticlePanier(histArt.Result.articleTab[0].article)
                      "
                    >
                      add_shopping_cart
                    </button>
                  </div>
                </div>
              </div> -->
            </div>
          </div>

          <!-- Deuxième pavé -->
          <div class="rounded-lg mx-3 h-full">
            <router-link to="/historiquePlaque">
              <h5
                class="font-medium text-bluevk w-full text-left hover:scale-110 duration-300 cursor-pointer"
              >
                Historique Véhicules
              </h5>
            </router-link>
            <hr class="md:my-1 border-t border-bluevk" />
            <div class="flex flex-col">
              <div
                class="bg-white rounded-lg p-1 mt-1"
                v-for="(histPla, index) in histPlaTab"
                :key="index"
              >
                <div class="flex justify-between">
                  <div class="flex items-center">
                    <img
                      src=""
                      alt=" "
                      class="bg-gray-300 rounded-sm ml-1 mr-2 text-xs text-gray-700 text-center"
                    />
                    <div v-if="!histPla.carData[0].error">
                      <div class="text-xs font-bold text-bluevk">
                        {{ histPla.carData[0].data.AWN_marque_carrosserie }} |
                        {{ histPla.carData[0].data.AWN_modele }}
                      </div>
                      <div
                        v-if="
                          histPla.carData[0].data
                            .AWN_date_mise_en_circulation &&
                          histPla.carData[0].data
                            .AWN_date_mise_en_circulation != ''
                        "
                        class="text-xs font-light text-gray-800"
                        title="Date mise en circulation"
                      >
                        Date mise en cir. :
                        {{
                          histPla.carData[0].data.AWN_date_mise_en_circulation
                        }}
                      </div>
                      <div v-else class="text-xs font-light text-gray-800">
                        Date mise en cir. : -
                      </div>
                      <div
                        v-if="histPla.plaque && histPla.plaque != ''"
                        class="text-xs font-medium text-orange-600"
                      >
                        {{ histPla.plaque.toUpperCase() }}
                      </div>
                      <div v-else class="text-xs font-medium text-orange-600">
                        -
                      </div>
                    </div>
                  </div>
                  <div class="flex space-x-1">
                    <button
                      v-if="
                        histPla.plaque &&
                        histPla.plaque != '' &&
                        !isLoadingPlaque
                      "
                      class="material-icons-round hover:text-gray-600 hover:animate-pulse"
                      style="font-size: 16px"
                      title="Reprendre la plaque"
                      @click="getPlaque(histPla.plaque)"
                    >
                      ads_click
                    </button>
                    <button
                      v-else-if="isLoadingPlaque"
                      class="material-icons-round hover:text-gray-600 animate-pulse"
                      style="font-size: 16px"
                      title="Reprendre la plaque"
                      @click="getPlaque(histPla.plaque)"
                    >
                      ads_click
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Texte informations -->
    <div class="mx-5">
      <marquee
        class="mt-4 shadow-lg rounded-md bg-gray-100 text-bluevk border-bluevk"
      >
        Bienvenue à toute l'équipe {{ user.proprietaire.toUpperCase() }} dans
        notre interface de commande de pièces automobiles ! Nous sommes ravis de
        vous accueillir sur notre interface dédiée aux professionnels.
        <!-- {{ scrollingText }} -->
      </marquee>
    </div>

    <!-- Promotions -->
    <div v-if="promoTab.length > 0" class="mt-4">
      <Carousel :tabPromo="promoTab" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import { useToast } from "vue-toastification";
import { ref } from "vue";
import {
  searchArticleClient,
  prepareArticleClient,
} from "@/hooks/searchEngine/article";
// import { getPanierTransportByClient } from "@/hooks/transport/panierTransport.ts";
// import ProgressCircle from "@/components/ProgressCircle.vue";
import Carousel from "@/components/Carousel.vue";
// import carouselNews from "@/components/carouselNews.vue";
import { getFrDate } from "@/hooks/tools/date.ts";
import hlsPlayer from "@/components/hlsPlayer";

export default {
  setup() {
    const toast = useToast();
    const completedSteps = ref(100);
    const totalSteps = ref(250);

    return { toast, completedSteps, totalSteps };
  },
  name: "MyDashboard",
  data() {
    return {
      open: false,
      nextCommande: false,
      checkPromo: [],
      promoselected: [],
      articleselected: "",
      marqueSelected: "",
      prefixeTarifSelected: "",
      promoArticle: {},
      description: [],
      Description: [],
      descript: {},
      showDescription: false,
      selecteddescription1: [],
      promoTab: [],
      promoTabLeft: [],
      promoTabRight: [],
      idArt: "",
      franco: "",
      fraisdeport: "",
      percent: 0,

      panierTransport: {},

      heures: 0,
      minutes: 0,
      secondes: 0,

      orderInProgress: 0,
      retourInProgress: 0,
      histArtTab: [],
      histPlaTab: [],

      scrollingText: "",
      isLoadingPlaque: false,

    };
  },
  components: {
    Carousel,
    hlsPlayer,
    // carouselNews,
  },
  computed: {
    ...mapGetters(["user", "plateform", "tabClient", "actionArticle"]),
  },
  methods: {
    ...mapActions([
      "addvehicule",
      "clearvehicule",
      "addactionArticle",
      "adddataconsigne",
      "adddatanonconsigne",
      "searchRefFournisseur",
      "setSelectionCarId",
    ]),
    getFRDate(date) {
      return getFrDate(date);
    },
    // async getPanierTransport() {
    //   this.panierTransport = await getPanierTransportByClient(
    //     this.user.plateform,
    //     this.user.proprietaire
    //   );
    //   console.log(this.panierTransport);
    // },
    getGroupClient() {
      axios
        .post(
          `${process.env.VUE_APP_API}/client/getone/${this.user.proprietaire}`,
          {
            plateform: this.user.plateform,
          }
        )
        .then((response) => {
          if (response.data[0].professionnel) {
            this.franco = response.data[0].francodeport;
          } else {
            this.fraisdeport = "0";
            this.transport = "0";
          }
        });
    },
    async getOrderInProgress() {
      const response = await axios.post(
        `${process.env.VUE_APP_API}/commandesClient/getInProgressByClient`,
        {
          plateform: this.user.plateform,
          client: this.user.proprietaire,
        }
      );
      this.orderInProgress = response.data;
    },
    async getRetourInProgress() {
      const response = await axios.post(
        `${process.env.VUE_APP_API}/retour/getInProgressByClient`,
        {
          plateform: this.user.plateform,
          client: this.user.proprietaire,
        }
      );
      this.retourInProgress = response.data;
    },
    async getHistArt() {
      const response = await axios.post(
        `${process.env.VUE_APP_API}/savedSearch/getLastFourClient`,
        {
          plateform: this.user.plateform,
          client: this.user.proprietaire,
        }
      );
      this.histArtTab = response.data;
    },
    async getHistPla() {
      const response = await axios.get(
        `${process.env.VUE_APP_API}/plaqueCRUD/getLastFourClient/${this.user.proprietaire}`
      );
      this.histPlaTab = response.data;
    },
    async getPromos() {
      let plateform_mere = this.user.plateform;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      const promoData = await axios.get(
        `${process.env.VUE_APP_API}/promo/image/${plateform_mere}`
      );

      this.promoTab = promoData.data;

      for (const [ind, promos] of this.promoTab.entries()) {
        if (ind % 2 == 0) {
          this.promoTabLeft.push(promos);
        } else {
          this.promoTabRight.push(promos);
        }
      }
    },
    // setDashPromo(urlPromo, Ref_fournisseur, Prefixe_tarif, marque, id) {
    //   this.open = true;
    //   this.promoselected = urlPromo;
    //   this.articleselected = Ref_fournisseur;
    //   this.prefixeTarifSelected = Prefixe_tarif;
    //   this.marqueSelected = marque;
    //   this.idArt = id;
    // },
    async ajoutArticlePanier(data) {
      var article;
      var searchTab = await searchArticleClient(
        data.Ref_fournisseur,
        this.user.plateform,
        this.user.proprietaire,
        this.tabClient.Group.Name
      );

      for (const art of searchTab) {
        if (art.article.Prefixe_tarif == data.Prefixe_tarif) {
          article = art.article;
          break;
        }
      }

      var existArticle = false;
      for (const [index, actArticle] of this.actionArticle.entries()) {
        if (
          actArticle.Ref_fournisseur == article.Ref_fournisseur &&
          actArticle.Prefixe_tarif == article.Prefixe_tarif
        ) {
          this.actionArticle[index].quantity =
            parseInt(this.actionArticle[index].quantity) + parseInt(1);
          existArticle = true;
        }
      }

      if (existArticle == false) {
        const articleData = { article: article, stockData: [] };
        var dataTab = await prepareArticleClient(
          articleData,
          this.user.plateform,
          this.user.proprietaire,
          this.tabClient.Group.Name,
          1
        );

        await this.addConsigne(dataTab);
        await this.addactionArticle(dataTab);
      }
      this.open = false;
      this.toast.success("Ajouté au panier !", {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    async addConsigne(dataConsigne) {
      const foundConsigne = await axios.post(
        `${process.env.VUE_APP_API}/article/getconsigne`,
        {
          data: dataConsigne,
        }
      );
      if (foundConsigne.data.length > 0) {
        if (this.user.proprietaire.dsvCheck == false) {
          this.addactionArticle({
            _id: dataConsigne._id,
            Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
            Prix_euro: dataConsigne.Montant_consigne,
            Prix_vente: dataConsigne.Montant_consigne,
            PrixFournisseurType: "prixnet",
            disabled: true,
            quantity: dataConsigne.quantity,
            quantityPrep: 0,
            quantityReturned: 0,
            remise: 0,
            prixtotal: dataConsigne.Prix_euro,
            prixfinal: dataConsigne.Prix_euro,
            Code_marque: dataConsigne.Code_marque,
            Description: dataConsigne.Description,
            commentaire: dataConsigne.commentaire,
          });
        }
        this.adddataconsigne(dataConsigne);
        this.adddataconsigne({
          _id: dataConsigne._id,
          Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
          Prix_euro: dataConsigne.Montant_consigne,
          Prix_vente: dataConsigne.Montant_consigne,
          PrixFournisseurType: "prixnet",
          disabled: true,
          quantity: dataConsigne.quantity,
          quantityPrep: 0,
          quantityReturned: 0,
          remise: 0,
          prixtotal: dataConsigne.Prix_euro,
          prixfinal: dataConsigne.Prix_euro,
          Code_marque: dataConsigne.Code_marque,
          Description: dataConsigne.Description,
          commentaire: dataConsigne.commentaire,
        });
      } else {
        this.adddatanonconsigne(dataConsigne);
      }
    },
    getPlaque(plaque) {
      this.clearvehicule();
      this.isLoadingPlaque = true;
      this.setSelectionCarId({ carId: null });
      axios
        .post(`${process.env.VUE_APP_API}/plaque`, {
          plaque: plaque,
          Owner: this.user.proprietaire,
        })
        .then((response) => {
          if (response.data.error) {
            this.toast.error("La plaque n'existe pas !", {
              position: "bottom-right",
              timeout: 2000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 1,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          } else {
            this.addvehicule(response.data);
            this.$router.push("/catalogueHubNew");
          }
          this.isLoadingPlaque = false;
        })
        .catch(() => {
          this.isLoadingPlaque = false;
        });
    },
    // updateCounter() {
    //   // Mettez à jour les heures, les minutes et les secondes
    //   const maintenant = new Date();
    //   this.heures = maintenant.getHours();
    //   this.minutes = maintenant.getMinutes();
    //   this.secondes = maintenant.getSeconds();
    // },
  },

  // beforeMount() {
  //   this.getPanierTransport();
  // },

  async mounted() {
    this.getGroupClient();
    this.getOrderInProgress();
    this.getRetourInProgress();
    this.getHistArt();
    this.getHistPla();
    this.getPromos();
    // setInterval(() => {
    //   this.updateCounter();
    // }, 1000);
  },
};
</script>

<style>
marquee {
  border: 1px solid #ccc;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}
</style>
