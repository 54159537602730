<template>
  <!-- Modal Detail BDL -->
  <div
    v-if="showDetailBdl"
    :class="`modal ${
      !showDetailBdl && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-screen top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="showDetailBdl = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      style="max-width: 70rem"
      class="z-50 w-11/12 mx-auto max-h-[40rem] bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Récapitulatif du bon de livraison</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="showDetailBdl = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div class="flex justify-between">
          <div class="w-1/3 ml-2">
            Référence client:
            {{ detailBdl.Note }}
          </div>

          <div class="flex flex-col my-auto items-center ml-auto mx-10">
            <span
              class="material-icons-round cursor-pointer shadow-lg rounded-full p-1 m-auto"
              style="color: blue"
              @click="showBL(detailBdl)"
            >
              download
            </span>
            <div>BL n° {{ detailBdl.Numero }}</div>
          </div>
        </div>

        <table class="min-w-full mt-4">
          <div class="overflow-y-auto max-h-[25rem]">
            <thead class="tableHEADTR">
              <tr>
                <th
                  class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Réf.
                </th>
                <th
                  class="mx-20 w-80 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Description
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Qté
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  P.V.
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Remise
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Px final
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Total
                </th>
              </tr>
            </thead>

            <tbody
              class="bg-white w-fit"
            >
              <tr
                v-for="(r, index) in detailPdf"
                :key="index"
                class="tableHEADTR"
              >
                <td
                  class="px-6 w-44 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div class="flex flex-row items-center">
                    <span class="ml-4 text-sm leading-5 text-gray-900">
                      {{ r.Ref_fournisseur }}
                    </span>
                  </div>
                </td>

                <td
                  class="px-6 w-80 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  style="overflow: none"
                >
                  <div class="text-sm leading-5 text-gray-500">
                    {{ r.Description }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div class="text-sm leading-5">
                    {{ r.quantity }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  {{
                    parseFloat(r.Prix_euro).toFixed(2)
                  }}
                  €
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div
                    v-if="
                      r.PrixFournisseurType != 'prixnet'
                    "
                  >
                    {{ r.remise }} %
                  </div>
                  <div v-else>prix net</div>
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{
                      r.PrixFournisseurType === "prixnet"
                        ? parseFloat(
                            r.Prix_vente
                          ).toFixed(2)
                        : parseFloat(
                            r.Prix_euro -
                              r.Prix_euro *
                                (r.remise / 100)
                          ).toFixed(2)
                    }}
                    €
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{
                      r.PrixFournisseurType === "prixnet"
                        ? parseFloat(r.Prix_vente * r.quantity).toFixed(2)
                        : parseFloat(
                            (r.Prix_euro - r.Prix_euro * (r.remise / 100)) *
                              r.quantity
                          ).toFixed(2)
                    }}€
                </td>
              </tr>
            </tbody>
          </div>
          <thead class="tableHEADTR mt-12 border-t">
            <tr class="tableHEADTR">
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="w-64 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                <div class="text-sm leading-5 text-gray-900">
                  Total HT :
                  {{ parseFloat(detailBdl.TotalPrice).toFixed(2) }} €
                </div>
                <div
                  v-if="detailBdl.TotalPriceTTC"
                  class="text-sm leading-5 text-gray-500"
                >
                  Total TTC :
                  {{ parseFloat(detailBdl.TotalPriceTTC).toFixed(2) }} €
                </div>
                <div v-else class="text-sm leading-5 text-gray-500">
                  Total TTC :
                  {{
                    parseFloat(
                      detailBdl.TotalPrice + detailBdl.TotalPrice * 0.2
                    ).toFixed(2)
                  }}
                  €
                </div>
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
  <!-- Fin modal Detail BDL -->

  <ModalRetour
    :detail="detailBdl"
    :isOpen="isModalOpen"
    @closeModal="closeModal"
    :askReturn="askReturn"
    @sendCheckRef="createReturn"
  />
  <div class="flex">
    <searchInput
      placeholder="Rechercher dans bons de livraison"
      @searchWord="search(1, 10, $event)"
      @catchFalse="searchCatchError = $event"
      :searchStarted="searchInProgress"
      :catchError="searchCatchError"
    /><titrePages
      class="flex justify-center flex-1"
      :title="'Bons de livraison'"
    />
  </div>

  <table class="min-w-full">
    <thead>
      <tr>
        <th
          style="width: 1em"
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          <span>Réf. client</span>
        </th>
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          <span>Date</span>
        </th>
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          <span>Numéro</span>
        </th>
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          <span>Client</span>
        </th>
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          <span>Créé par</span>
        </th>
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          HT
        </th>
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          TTC
        </th>
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          PDF
        </th>
        <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
      </tr>
    </thead>

    <tbody class="bg-white">
      <tr
        v-for="(u, index) in infosBdl"
        :key="index"
        class="hover:bg-gray-100 cursor-pointer hover:shadow"
      >
        <td
          class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap overflow-x-auto"
        >
          <p class="max-w-[12rem]">{{ u.Note }}</p>
        </td>
        <td
          class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
          style="width: 1em"
        >
          <div class="flex items-center">
            <div class="text-sm leading-5 text-gray-900">
              {{ getFRDate(u.Date) }}
            </div>
          </div>
        </td>

        <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
          <div class="text-sm leading-5 text-gray-900">
            {{ u.Numero }}
            <span
              @click="openModalDetail(u)"
              title="Plus d'informations"
              class="material-icons-outlined text-base text-cyan-600"
            >
              info
            </span>
          </div>
          <div
            class="text-sm leading-5 text-gray-900"
            v-if="
              u.textFile &&
              u.textFile.codeClient != plateform.dasilva.codeClient
            "
          >
            {{ u.Fournisseur }}
          </div>
        </td>

        <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
          {{ u.Client }}
        </td>

        <td
          class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
        >
          {{ u.Name }}
        </td>

        <td
          class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
        >
          {{ parseFloat(u.TotalPrice).toFixed(2) }} €
        </td>

        <td
          v-if="u.TotalPriceTTC"
          class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
        >
          {{ parseFloat(u.TotalPriceTTC).toFixed(2) }} €
        </td>
        <td
          v-else
          class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
        >
          {{ parseFloat(u.TotalPrice + u.TotalPrice * 0.2).toFixed(2) }}
          €
        </td>

        <td
          class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
        >
          <div class="flex space-x-4">
            <span
              class="material-icons-round cursor-pointer text-blue-600 hover:text-opacity-25"
              @click="showBL(u)"
            >
              download
            </span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <pageNumber
    v-if="!searchActive"
    :pageStart="pageStart"
    :pageNext="pageNext"
    :tabList="bdlList"
    :pageTab="pageTab"
    :pageTotal="Math.ceil(bdlList / 10)"
    :clickFunction="getPage"
    :currentPage="currentPage"
    :typeList="'bons de livraison'"
    @update:start="pageStart = $event"
    @update:tab="pageTab = $event"
    @update:next="pageNext = $event"
  />

  <pageNumber
    v-if="searchActive"
    :pageStart="pageStart"
    :pageNext="pageNext"
    :tabList="searchLength"
    :pageTab="pageTab"
    :pageTotal="Math.ceil(searchLength / 10)"
    :clickFunction="(arg1, arg2) => search(arg1, arg2, searchTerm)"
    :currentPage="currentPage"
    :typeList="'bons de livraison'"
    @update:start="pageStart = $event"
    @update:tab="pageTab = $event"
    @update:next="pageNext = $event"
  />
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import { createlivraison } from "../../components/pdf/livraison.js";
import pageNumber from "@/components/pageNumber.vue";
import ModalRetour from "@/components/Retour/ModalRetour.vue";
import searchInput from "@/components/searchInput.vue";
import { createGroupedDoc } from "@/components/pdf/groupedDoc.js";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";
import titrePages from "@/components/Text/grandeTitre.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "MyBdl",
  data() {
    return {
      infosBdl: [],
      infosBdl2: [],
      detailBdl: [],
      detailPdf: [],

      askReturn: false,
      isChecked: [],
      articleTab: [],
      retourCreate: [],
      checkedRef: [],
      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      currentPage: 1,
      bdlList: 0,

      isModalOpen: false,
      qtyReturned: 0,
      searchActive: false,
      searchTerm: "",
      searchLength: 0,
      searchInProgress: false,
      searchCatchError: false,
      showDetailBdl: false,
    };
  },
  components: {
    pageNumber,
    ModalRetour,
    searchInput,
    titrePages,
  },
  computed: {
    ...mapGetters(["user", "plateform"]),
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    openModal(u, setAskReturn) {
      this.detailBdl = u;
      this.askReturn = setAskReturn;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.infoInBdl = false;
    },
    getPage(skipValue, limitValue) {
      this.currentPage = skipValue;
      axios
        .post(`${process.env.VUE_APP_API}/bdl/list/${this.user.plateform}`, {
          client: this.user.proprietaire,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((res) => {
          if (res.data.length > 0) {
            this.infosBdl = res.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPageTotal() {
      axios
        .post(
          `${process.env.VUE_APP_API}/bdl/countBdl/${this.user.plateform}`,
          {
            client: this.user.proprietaire,
          }
        )
        .then((res) => {
          this.bdlList = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInfosCommandes() {
      axios
        .post(`${process.env.VUE_APP_API}/bdl/getAllBdlByClient`, {
          plateform: this.user.plateform,
          client: this.user.proprietaire,
        })
        .then((response) => {
          this.infosBdl = response.data;
        });
    },
    calculateQuantiteReturn() {
      this.detailBdl.Articles.forEach((bdl) => {
        const qtyReturned = bdl.qtyReturned || 0;
        const qtyRemaining = bdl.quantite - qtyReturned;
        this.$set(bdl, "qtyRemaining", qtyRemaining);
      });
    },
    createReturn(checkRef) {
      this.checkedRef = checkRef;
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;
      let articlesSelectionnes = [];
      let articlesPdfData = [];
      var totalRetour = 0.0;
      var totalRetourConsigne = 0.0;
      let articlesSelectionnesConsigne = [];
      let articlesPdfDataConsigne = [];
      let articlesSelectionnesAutre = [];
      let articlesPdfDataAutre = [];

      for (let i = 0; i < this.checkedRef.length; i++) {
        this.ref = this.detailBdl.Articles[this.checkedRef[i]].Ref_fournisseur;
        this.articleIndex = this.detailBdl.Articles[this.checkedRef[i]]._id;
        this.newQuantity =
          this.detailBdl.Articles[this.checkedRef[i]].quantityPrep +
          this.detailBdl.Articles[this.checkedRef[i]].quantityReturned;
        articlesSelectionnes.push({
          Ref_fournisseur:
            this.detailBdl.Articles[this.checkedRef[i]].Ref_fournisseur,
          Description: this.detailBdl.Articles[this.checkedRef[i]].Description,
          Prix_euro: this.detailBdl.Articles[this.checkedRef[i]].Prix_euro,
          Prix_net: this.detailBdl.Articles[this.checkedRef[i]].Prix_vente,
          quantity: -this.detailBdl.Articles[this.checkedRef[i]].quantityPrep,
          Remise: this.detailBdl.Articles[this.checkedRef[i]].remise,
          remiseSupp: this.detailBdl.Articles[this.checkedRef[i]].remiseSupp,
          total:
            this.detailBdl.Articles[this.checkedRef[i]].Prix_vente *
            this.detailBdl.Articles[this.checkedRef[i]].quantityPrep,
          Remisegenerale:
            this.detailBdl.Articles[this.checkedRef[i]].remisegenerale,
        });
        articlesPdfData.push({
          description: this.detailBdl.Articles[this.checkedRef[i]].Description,
          ref: this.detailBdl.Articles[this.checkedRef[i]].Ref_fournisseur,
          quantite: -this.detailBdl.Articles[this.checkedRef[i]].quantityPrep,
          remise: this.detailBdl.Articles[this.checkedRef[i]].remise,
          remisegenerale:
            this.detailBdl.Articles[this.checkedRef[i]].remisegenerale,
          prixU: this.detailBdl.Articles[this.checkedRef[i]].prixU,
          Prix_net: this.detailBdl.Articles[this.checkedRef[i]].Prix_vente,
          total:
            this.detailBdl.Articles[this.checkedRef[i]].Prix_vente *
            this.detailBdl.Articles[this.checkedRef[i]].quantityPrep,
          totalSansRemise:
            -this.detailBdl.Articles[this.checkedRef[i]].totalSansRemise,
          commentaire: "",
        });

        if (
          this.detailBdl.Articles[
            this.checkedRef[i]
          ].Ref_fournisseur.startsWith("C :")
        ) {
          if (this.detailBdl.Articles[this.checkedRef[i]].quantityPrep > 1) {
            totalRetourConsigne +=
              this.detailBdl.Articles[this.checkedRef[i]].Prix_vente *
              -this.detailBdl.Articles[this.checkedRef[i]].quantityPrep;
          } else {
            totalRetourConsigne +=
              -this.detailBdl.Articles[this.checkedRef[i]].Prix_vente;
          }
        } else {
          if (this.detailBdl.Articles[this.checkedRef[i]].quantityPrep > 1) {
            totalRetour +=
              this.detailBdl.Articles[this.checkedRef[i]].Prix_vente *
              -this.detailBdl.Articles[this.checkedRef[i]].quantityPrep;
          } else {
            totalRetour +=
              -this.detailBdl.Articles[this.checkedRef[i]].Prix_vente;
          }
        }

        if (
          this.detailBdl.Articles[
            this.checkedRef[i]
          ].Ref_fournisseur.startsWith("C :")
        ) {
          articlesSelectionnesConsigne.push(articlesSelectionnes[i]);
          articlesPdfDataConsigne.push(articlesPdfData[i]);
        } else {
          articlesSelectionnesAutre.push(articlesSelectionnes[i]);
          articlesPdfDataAutre.push(articlesPdfData[i]);
        }

        axios.put(
          `${process.env.VUE_APP_API}/bdl/return/${this.detailBdl._id}`,
          {
            _id: this.articleIndex,
            quantityReturned: this.newQuantity,
          }
        );
      }

      if (articlesSelectionnesConsigne.length > 0) {
        axios
          .post(`${process.env.VUE_APP_API}/retourConsigne`, {
            plateform: this.user.plateform,
            Client: this.user.proprietaire,
            Name: this.user.username,
            // Date: today,
            clientInfo: this.detailBdl.clientInfo,
            Articles: articlesSelectionnesConsigne,
            pdfData: articlesPdfDataConsigne,
            NumeroBl: parseInt(this.detailBdl.Numero),
            reglement: [],
            Note: this.detailBdl.Note,
            type: this.detailBdl.type,
            status: "Retour demandé",
            Archived: false,
            Totalretour: totalRetourConsigne,
          })
          .then((response) => {
            this.retourCreate = response.data;
            this.toast.success("Retour créé !", {
              position: "bottom-right",
              timeout: 1000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 1,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            this.infoInBdl = false;
          });
      }

      if (articlesSelectionnesAutre.length > 0) {
        axios
          .post(`${process.env.VUE_APP_API}/retour`, {
            plateform: this.user.plateform,
            Client: this.user.proprietaire,
            Name: this.user.username,
            // Date: today,
            clientInfo: this.detailBdl.clientInfo,
            Articles: articlesSelectionnesAutre,
            pdfData: articlesPdfDataAutre,
            NumeroBl: parseInt(this.detailBdl.Numero),
            reglement: [],
            Note: this.detailBdl.Note,
            type: this.detailBdl.type,
            status: "Retour demandé",
            Archived: false,
            Totalretour: totalRetour,
          })
          .then((response) => {
            this.retourCreate = response.data;
            this.toast.success("Retour créé !", {
              position: "bottom-right",
              timeout: 1000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 1,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            this.infoInBdl = false;
          });
      }

      this.checkedRef = [];
      this.closeModal();
      this.getInfosCommandes();
      this.getPage(this.currentPage, 10);
      this.infoInBdl = false;
    },
    getTotal() {
      let total = 0.0;
      for (const index of this.checkedRef) {
        total += this.detailBdl.Articles[index].total;
      }
      return total;
    },
    getDetailBdl(u) {
      this.detailBdl = u;
    },
    showBL(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        if (objet.TotalPrice >= 0) {
          createlivraison(objet, false, false);
        } else {
          createlivraison(objet, false, true);
        }
      } else if (objet.TotalPrice >= 0) {
        createlivraison(objet, true, false);
      } else {
        createlivraison(objet, true, true);
      }
    },
    openGroupedPDF(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createGroupedDoc(objet, "Bon de livraison", "BL", false);
      } else {
        createGroupedDoc(objet, "Bon de livraison", "BL", true);
      }
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    search(skipValue, limitValue, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getPage(skipValue, limitValue);
        this.getPageTotal();
        this.pageSwitch();
      } else {
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(`${process.env.VUE_APP_API}/bdl/searchInClient`, {
            plateform: this.user.plateform,
            client: this.user.proprietaire,
            searchTerm: searchTerm,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          })
          .then((response) => {
            this.searchInProgress = false;
            this.infosBdl = response.data.results;
            this.searchLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
    openModalDetail(data) {
      this.detailBdl = data;
      this.detailPdf = data.Articles;
      this.showDetailBdl = true;
    },
  },
  mounted() {
    this.getPageTotal();
    this.pageSwitch();
    this.getPage(1, 10);
  },
};
</script>
<style>
.modal {
  transition: opacity 0.25s ease;
}
</style>
