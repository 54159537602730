import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from "vue";
import { useRouter } from "vue-router";

const defaultLayout = "default";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { currentRoute } = useRouter();

const layout = computed(
  () => `${currentRoute.value.meta.layout || defaultLayout}-layout`
);

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(layout.value), null, {
    default: _withCtx(() => [
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}
}

})