import pdfMake from "pdfmake/build/pdfmake.js";
import pdfFonts from "pdfmake/build/vfs_fonts.js";
import store from "../../store";
import { getFrDate } from "@/hooks/tools/date.ts";

export function createdevis(objet, duplicata) {
  var today = new Date();
  var da = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  var hour = today.getHours();
  var minutes = today.getMinutes();
  var edition =
    "Edité le " + da + "/" + mm + "/" + yyyy + " à " + hour + ":" + minutes;
  var datepdf = "Le " + getFrDate(objet.Date) + ",";

  let colDuplicata;
  if (duplicata) {
    colDuplicata = {
      columns: [
        {
          text: "Duplicata",
          style: "invoiceTitleDetail",
          margin: [0, 0, 0, 0],
          width: 390,
        },
      ],
    };
  }

  var produits = [];

  produits.push([
    {
      text: "Qté",
      style: ["itemsHeader", "center"],
      border: [true, true, false, false],
      borderColor: ["#949494", "#949494", "#949494", "#949494"],
      fillColor: "#EEEEEE",
    },
    {
      text: "Produit",
      style: "itemsHeader",
      border: [false, true, false, false],
      borderColor: ["#949494", "#949494", "#949494", "#949494"],
      fillColor: "#EEEEEE",
    },
    {
      text:
        "Servi par : " +
        objet.Name +
        "            " +
        "réf. cl. : " +
        objet.Note,
      style: "itemsHeader",
      border: [false, true, false, false],
      borderColor: ["#949494", "#949494", "#949494", "#949494"],
      fillColor: "#EEEEEE",
    },
    {
      text: store.state.tabClient?.autoEntrepreneurState
        ? "Px brut"
        : "Px brut HT",
      style: ["itemsHeader", "center"],
      border: [false, true, false, false],
      borderColor: ["#949494", "#949494", "#949494", "#949494"],
      fillColor: "#EEEEEE",
    },
    {
      text: "Remise",
      style: ["itemsHeader", "center"],
      border: [false, true, false, false],
      borderColor: ["#949494", "#949494", "#949494", "#949494"],
      fillColor: "#EEEEEE",
    },
    {
      text: store.state.tabClient?.autoEntrepreneurState
        ? "Px net"
        : "Px net HT",
      style: ["itemsHeader", "center"],
      border: [false, true, false, false],
      borderColor: ["#949494", "#949494", "#949494", "#949494"],
      fillColor: "#EEEEEE",
    },
    {
      text: store.state.tabClient?.autoEntrepreneurState ? "Mt TTC" : "Mt HT",
      style: ["itemsHeader", "center"],
      border: [false, true, true, false],
      borderColor: ["#949494", "#949494", "#949494", "#949494"],
      fillColor: "#EEEEEE",
    },
    // {
    //   text: "Mt TTC",
    //   style: ["itemsHeader", "center"],
    //   border: [false, true, true, false],
    //   borderColor: ["#949494", "#949494", "#949494", "#949494"],
    //   fillColor: "#EEEEEE",
    // },
  ]);
  objet.Articles.forEach((element, index) => {
    var prixTTC = objet.Articles[index].Prix_euro;
    var prixRemise;
    var remise;
    prixRemise = parseFloat(prixTTC - prixTTC * (element.remise / 100)).toFixed(
      2
    );
    remise = element.remise + "%";
    var montant_ttc = store.state.tabClient?.autoEntrepreneurState
      ? (
          parseFloat(prixRemise * element.quantity) * 0.2 +
          parseFloat(prixRemise * element.quantity)
        ).toFixed(2)
      : parseFloat(prixRemise * element.quantity).toFixed(2);
    produits.push([
      {
        text: element.quantity,
        style: "itemNumber",
        border: [true, false, true, false],
        borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
        fillColor: "#F7F7F7",
      },
      {
        text:
          element.Ref_fournisseur +
          "\n" +
          (objet.Articles[index]?.Code_marque?.charAt(0).toUpperCase() +
            objet.Articles[index]?.Code_marque?.slice(1).toLowerCase() || ""),
        style: "itemTitle",
        border: [true, false, false, false],
        borderColor: ["#949494", "#949494", "#949494", "#949494"],
        fillColor: "#FAFAFA",
      },
      {
        text:
          element.commentaire && element.commentaire != ""
            ? element.Description + "\n \n" + "Note : " + element.commentaire
            : element.Description,
        style: "itemTitle",
        border: [false, false, true, false],
        borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
        fillColor: "#F7F7F7",
      },
      {
        text: parseFloat(prixTTC).toFixed(2),
        style: "itemNumber",
        border: [true, false, true, false],
        borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
        fillColor: "#FAFAFA",
      },
      {
        text: remise,
        style: "itemNumber",
        border: [true, false, true, false],
        borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
        fillColor: "#F7F7F7",
      },
      {
        text: parseFloat(prixRemise).toFixed(2),
        style: "itemNumber",
        border: [true, false, false, false],
        borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
        fillColor: "#FAFAFA",
      },
      {
        text: montant_ttc,
        style: "itemNumber",
        border: [false, false, true, false],
        borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
        fillColor: "#F7F7F7",
      },
      // {
      //   text: montantTTC,
      //   style: "itemNumber",
      //   border: [false, false, true, false],
      //   borderColor: ["#FFFFFF", "#949494", "#949494", "#949494"],
      //   fillColor: "#FAFAFA",
      // },
    ]);
  });
  var footerBody = {};
  // if (objet.pdfData[0].remisegenerale && objet.pdfData[0].remisegenerale != 0) {
  //   footerBody = {
  //     headerRows: 0,
  //     widths: [180, 80, "*", 80, "*"],

  //     body: [
  //       [
  //         {
  //           text: "",
  //           style: "itemsFooterTotalTitle",
  //           borderColor: ["#949494", "#949494", "#949494", "#949494"],
  //         },
  //         {
  //           text: "",
  //           style: "itemsFooterTotalTitle",
  //           borderColor: ["#949494", "#949494", "#949494", "#949494"],
  //         },
  //         {
  //           text: "",
  //           style: "itemsFooterTotalValue",
  //           borderColor: ["#949494", "#949494", "#949494", "#949494"],
  //         },
  //         {
  //           text: store.state.tabClient?.autoEntrepreneurState
  //             ? "TVA non applicable, article 293 B du CGI"
  //             : "Total HT : ",
  //           style: "itemsFooterTotalTitle",
  //         },
  //         {
  //           text: store.state.tabClient?.autoEntrepreneurState
  //             ? ""
  //             : parseFloat(objet.pdfData[0].totalSansRemise).toFixed(2) + " €",
  //           style: "itemsFooterTotalValue1",
  //         },
  //       ],
  //       // [
  //       //   {
  //       //     text: "",
  //       //     style: "itemsFooterTotalTitle",
  //       //     borderColor: ["#949494", "#949494", "#949494", "#949494"],
  //       //   },
  //       //   {
  //       //     text: "Remise : ",
  //       //     style: "itemsFooterTotalTitle",
  //       //   },
  //       //   {
  //       //     text: parseFloat(objet.pdfData[0].remisegenerale) + " %",
  //       //     style: "itemsFooterTotalValue",
  //       //   },
  //       //   {
  //       //     text: "Total HT remisé : ",
  //       //     style: "itemsFooterTotalTitle",
  //       //     borderColor: ["#949494", "#949494", "#949494", "#949494"],
  //       //   },
  //       //   {
  //       //     text: parseFloat(objet.TotalPrice).toFixed(2) + " €",
  //       //     style: "itemsFooterTotalValue1",
  //       //     borderColor: ["#949494", "#949494", "#949494", "#949494"],
  //       //   },
  //       // ],
  //       [
  //         {
  //           text: "",
  //           style: "itemsFooterTotalTitle",
  //           borderColor: ["#949494", "#949494", "#949494", "#949494"],
  //         },
  //         {
  //           text: "TVA : ",
  //           style: "itemsFooterTotalTitle",
  //           borderColor: ["#949494", "#949494", "#949494", "#949494"],
  //         },
  //         {
  //           text: parseFloat(objet.TotalPrice * 0.2).toFixed(2) + " €",
  //           style: "itemsFooterTotalValue",
  //           borderColor: ["#949494", "#949494", "#949494", "#949494"],
  //         },
  //         {
  //           text: "Total TTC : ",
  //           style: "itemsFooterTotalTitle",
  //           borderColor: ["#949494", "#949494", "#949494", "#949494"],
  //         },
  //         {
  //           text:
  //             parseFloat(objet.TotalPrice + objet.TotalPrice * 0.2).toFixed(2) +
  //             " €",
  //           style: "itemsFooterTotalValue1",
  //           borderColor: ["#949494", "#949494", "#949494", "#949494"],
  //         },
  //       ],
  //     ],
  //   };
  // } else {
  if (store.state.tabClient?.autoEntrepreneurState) {
    footerBody = {
      headerRows: 0,
      widths: [100, 80, "*", 80, "*"],

      body: [
        [
          {
            text: "",
            style: "itemsFooterTotalTitle",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text: "",
            style: "itemsFooterTotalTitle",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text: "",
            style: "itemsFooterTotalValue",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text: "",
            style: "itemsFooterTotalTitle",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text: "",
            style: "itemsFooterTotalValue1",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
        ],
        [
          {
            text: "",
            style: "itemsFooterTotalTitle",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text: "TVA non applicable, article 293 B du CGI",
            style: "itemsFooterTVATitle",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text: "",
            style: "itemsFooterTotalValue",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text: "Total TTC : ",
            style: "itemsFooterTotalTitle",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text:
              parseFloat(objet.TotalPrice + objet.TotalPrice * 0.2).toFixed(2) +
              " €",
            style: "itemsFooterTotalValue1",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
        ],
      ],
    };
  } else {
    footerBody = {
      headerRows: 0,
      widths: [180, 80, "*", 80, "*"],

      body: [
        [
          {
            text: "",
            style: "itemsFooterTotalTitle",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text: "",
            style: "itemsFooterTotalTitle",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text: "",
            style: "itemsFooterTotalValue",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text: "Total HT : ",
            style: "itemsFooterTotalTitle",
          },
          {
            text: parseFloat(objet.TotalPrice).toFixed(2) + " €",
            style: "itemsFooterTotalValue1",
          },
        ],
        [
          {
            text: "",
            style: "itemsFooterTotalTitle",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text: "TVA : ",
            style: "itemsFooterTotalTitle",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text: parseFloat(objet.TotalPrice * 0.2).toFixed(2) + " €",
            style: "itemsFooterTotalValue",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text: "Total TTC : ",
            style: "itemsFooterTotalTitle",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
          {
            text:
              parseFloat(objet.TotalPrice + objet.TotalPrice * 0.2).toFixed(2) +
              " €",
            style: "itemsFooterTotalValue1",
            borderColor: ["#949494", "#949494", "#949494", "#949494"],
          },
        ],
      ],
    };
  }
  // }

  pdfMake.vfs = pdfFonts.vfs;

  var dd = {
    header: [
      {
        columns: [
          {
            text: edition,
            style: "documentHeaderRight",
          },
        ],
      },
      // Header
      {
        margin: [40, 0, 0, 0],
        columns: [
          // TODO IMAGE HERE
          {
            text: "",
            width: 100,
            height: 100,
          },
          // margin: [left, top, right, bottom]
          [
            {
              columns: [
                {
                  text: "Devis",
                  style: "invoiceTitle",
                  margin: [0, 0, 0, 0],
                  width: 390,
                },
              ],
            },
            {
              columns: [
                {
                  text: "n°" + "100000" + objet.Numero,
                  style: "invoiceTitleDetail",
                  margin: [0, 0, 0, 0],
                  width: 390,
                },
              ],
            },
            colDuplicata,
            {
              stack: [
                {
                  columns: [
                    {
                      text: "",
                      style: "invoiceSubValue",
                      width: 180,
                    },
                    {
                      text: datepdf,
                      style: "invoiceSubValue",
                      margin: [0, 40, 0, 0],
                      width: 190,
                    },
                  ],
                },
              ],
            },
          ],
        ],
      },
      // Billing Headers
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: store.state.tabClient.Name
              ? store.state.tabClient.Name + "\n"
              : "",
            style: "invoiceBillingTitle",
            width: 245,
            margin: [0, 5, 0, 0],
          },
          {
            text: objet.Client || "",
            style: "invoiceBillingTitle1",
            width: 245,
            margin: [25, 0, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: store.state.tabClient.Adresse
              ? store.state.tabClient.Adresse + "\n"
              : "",
            style: "invoiceBillingSubTitle",
            width: 245,
            margin: [0, 0, 0, 0],
          },
          {
            text:
              objet.clientInfo.length > 0 && objet.clientInfo[0].Adresse
                ? objet.clientInfo[0].Adresse
                : "",
            style: "invoiceBillingDetails",
            width: 245,
            margin: [24, -5, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: store.state.tabClient.Complement
              ? store.state.tabClient.Complement
              : "",
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, 0, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetails",
            width: 0,
            margin: [0, 10, 0, 0],
          },
          {
            text:
              objet.clientInfo.length > 0 && objet.clientInfo[0].Complement
                ? objet.clientInfo[0].Complement
                : "",
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, -8, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text:
              store.state.tabClient.PostalCode && store.state.tabClient.City
                ? store.state.tabClient.PostalCode +
                  " " +
                  store.state.tabClient.City
                : "",
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, 0, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetails",
            width: 0,
            margin: [0, 0, 0, 0],
          },
          {
            text:
              objet.clientInfo.length > 0 &&
              objet.clientInfo[0].PostalCode &&
              objet.clientInfo[0].City
                ? objet.clientInfo[0].PostalCode +
                  " " +
                  objet.clientInfo[0].City
                : "",
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, -5, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: store.state.tabClient.Country
              ? store.state.tabClient.Country
              : "",
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, 0, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetails",
            width: 0,
            margin: [0, 0, 0, 0],
          },
          {
            text:
              objet.clientInfo.length > 0 && objet.clientInfo[0].Country
                ? objet.clientInfo[0].Country
                : "",
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, -6, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: store.state.tabClient.telephone
              ? store.state.tabClient.telephone
              : "",
            style: "invoiceBillingContactCompany",
            width: 210,
            margin: [0, 9, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingContactCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
          {
            text:
              objet.clientInfo.length > 0 && objet.clientInfo[0].telephone
                ? objet.clientInfo[0].telephone
                : "",
            style: "invoiceBillingContactCompany",
            width: 210,
            margin: [0, -1, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: store.state.tabClient.email
              ? store.state.tabClient.email
              : "",
            style: "invoiceBillingContactCompany",
            width: 210,
            margin: [0, 0, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
          {
            text:
              objet.clientInfo.length > 0 && objet.clientInfo[0].email
                ? objet.clientInfo[0].email
                : "",
            style: "invoiceBillingContactCompany",
            width: 210,
            margin: [0, -10, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 210,
            margin: [0, 2, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 210,
            margin: [0, -8, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 210,
            margin: [0, 5, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
          {
            text:
              objet.clientInfo.length > 0 && objet.clientInfo[0].NumeroClient
                ? "N° Client : " + objet.clientInfo[0].NumeroClient
                : "",
            style: "invoiceBillingDetailsCompany",
            width: 210,
            margin: [0, -5, 0, 0],
          },
        ],
      },
    ],
    footer: function (currentPage, pageCount) {
      return [
        {
          columns: [
            {
              text: "",
              style: "documentFooterCenter",
            },
          ],
        },
        {
          columns: [
            {
              text: `Page ${currentPage} / ${pageCount}`,
              style: "documentFooterCenter1",
            },
          ],
        },
      ];
    },
    content: [
      // Items
      {
        table: {
          headerRows: 1,
          widths: [30, 60, 190, 50, 30, 40, 50],
          body: produits,
        },
      },
      "\n\n",
      {
        table: footerBody,
        layout: "lightHorizontalLines",
      },
    ],
    styles: {
      // Document Header
      documentHeaderRight: {
        fontSize: 10,
        margin: [0, 10, 15, 5],
        alignment: "right",
      },
      documentHeaderCenter: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "center",
      },
      // Document Footer
      documentFooterLeft: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "left",
      },
      documentFooterCenter: {
        fontSize: 6,
        margin: [0, 8, 0, 0],
        alignment: "center",
      },
      documentFooterCenter1: {
        fontSize: 9,
        margin: [0, 1, 0, 0],
        alignment: "center",
      },
      documentFooterRight: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "right",
      },
      // Invoice Title
      invoiceTitle: {
        fontSize: 22,
        bold: true,
        alignment: "right",
        margin: [0, 0, 0, 15],
      },
      invoiceTitleDetail: {
        fontSize: 10,
        bold: true,
        alignment: "right",
        margin: [0, 0, 0, 15],
      },
      // Invoice Details
      invoiceSubValue: {
        fontSize: 9,
        alignment: "right",
      },
      // Billing Headers
      invoiceBillingTitle: {
        fontSize: 14,
        bold: true,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      invoiceBillingSubTitle: {
        fontSize: 14,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      invoiceBillingTitle1: {
        fontSize: 10,
        bold: true,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      invoiceBillingSubTitle1: {
        fontSize: 14,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      // Billing Details
      invoiceBillingDetails: {
        margin: [0, 0, 0, 0],
        fontSize: 11,
        alignment: "left",
      },
      invoiceBillingContactCompany: {
        fontSize: 8,
      },
      invoiceBillingDetailsCompany: {
        fontSize: 7,
      },
      // Items Header
      itemsHeader: {
        margin: [0, 2, 0, 2],
        fontSize: 7,
        bold: true,
        fillColor: "#CECECE",
      },
      // Item Title
      itemTitle: {
        fontSize: 8,
      },
      itemNumber: {
        margin: [0, 2, 0, 2],
        fontSize: 7,
        alignment: "center",
      },
      itemTotal: {
        margin: [0, 2, 0, 2],
        fontSize: 10,
        bold: true,
        alignment: "center",
      },

      // Items Footer (Subtotal, Total, Tax, etc)
      itemsFooterTVATitle: {
        margin: [0, 5, 0, 5],
        fontSize: 8,
        italic: true,
        alignment: "left",
      },
      itemsFooterTotalTitle: {
        margin: [0, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "left",
      },
      itemsFooterTotalValue: {
        margin: [-30, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "left",
      },
      itemsFooterTotalValue1: {
        margin: [-10, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "right",
      },
      itemsFooterResteTitle: {
        fontSize: 8,
        margin: [0, 2, 0, 2],
        bold: true,
        fillColor: "#CECECE",
      },
      itemsFooterReste: {
        fontSize: 8,
        margin: [0, 2, 0, 2],
      },
      //signaturePlaceholder: {
      //    margin: [0,70,0,0],
      //},
      //signatureName: {
      //    bold: true,
      //    alignment:'center',
      //},
      //signatureJobTitle: {
      //    italics: true,
      //    fontSize: 10,
      //    alignment:'center',
      //},
      notesTitle: {
        fontSize: 10,
        bold: true,
        margin: [0, 50, 0, 3],
      },
      notesText: {
        fontSize: 10,
      },
      center: {
        alignment: "center",
      },
    },
    pageMargins: [40, 260, 40, 40],
    defaultStyle: {
      columnGap: 20,
    },
  };
  pdfMake.createPdf(dd).open();
}
